import { INTERNAL_PROP_PREFIX } from 'emma-common-ts';

// eslint-disable-next-line @typescript-eslint/ban-types
export function cleanUpTempProps<T extends object | unknown>(data: T): T {
  if (!data || 'object' !== typeof data) {
    return data;
  }
  const clean: T = {} as T;
  for (const prop of Object.getOwnPropertyNames(data)) {
    if (!prop.startsWith(INTERNAL_PROP_PREFIX)) {
      clean[prop] = data[prop];
    }
  }
  return clean;
}
