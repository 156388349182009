import { Component, Input } from '@angular/core';
import { AccountUser, ROUTE, SKUDO_PERMISSION_ID, User } from 'emma-common-ts/skudo';

@Component({
  selector: 'skudo-user-menu',
  templateUrl: './skudo-user-menu.component.html',
  styleUrls: ['./skudo-user-menu.component.css'],
})
export class SkudoUserMenuComponent {
  @Input() user!: User;
  @Input() currentAccount!: AccountUser;

  ROUTE = ROUTE;
  PERMISSIONS = SKUDO_PERMISSION_ID;
}
