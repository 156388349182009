import { NgModule } from '@angular/core';
import { PlatformComponent } from './platform.component';

import { JoinPipe } from '../lib/pipes/join.pipe';

@NgModule({
  declarations: [PlatformComponent, JoinPipe],
  imports: [],
  exports: [PlatformComponent, JoinPipe],
})
export class PlatformModule {}
