import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SkudoFooterModule } from '@skudo/components/skudo-footer/skudo-footer.module';
import { SkudoHeaderModule } from '@skudo/components/skudo-header/skudo-header.module';
import { SkudoUiModule } from '@skudo/components/skudo-ui.module';
import { AuthGuard } from '@skudo/guards/auth.guard';
import { SkudoPrivateWrapperComponent } from '@skudo/pages/private/private-wrapper.component';

const routes: Routes = [
  {
    path: '',
    component: SkudoPrivateWrapperComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'index',
        loadChildren: () =>
          import('@skudo/pages/index/index-routing.module').then((m) => m.IndexRoutingModule),
      },
      {
        path: 'campaigns',
        loadChildren: () =>
          import('@skudo/pages/private/campaigns/campaigns-routing.module').then(
            (m) => m.CampaignsRoutingModule
          ),
      },
      {
        path: 'events',
        loadChildren: () =>
          import('@skudo/pages/private/events/events-routing.module').then((m) => m.EventsRoutingModule),
      },
      {
        path: 'main',
        loadChildren: () =>
          import('@skudo/pages/private/main/main-routing.module').then((m) => m.MainRoutingModule),
      },
      {
        path: 'offers',
        loadChildren: () =>
          import('@skudo/pages/private/offers/offers-routing.module').then((m) => m.OffersRoutingModule),
      },
      {
        path: 'providers',
        loadChildren: () =>
          import('@skudo/pages/private/providers/providers-routing.module').then(
            (m) => m.ProvidersRoutingModule
          ),
      },
      {
        path: 'fraud',
        loadChildren: () =>
          import('@skudo/pages/private/fraud/fraud-routing.module').then((m) => m.FraudRoutingModule),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('@skudo/pages/private/reports/reports-routing.module').then((m) => m.ReportsRoutingModule),
      },
      {
        path: 'user',
        loadChildren: () =>
          import('@skudo/pages/private/user/user-routing.module').then((m) => m.UserRoutingModule),
      },
    ],
  },
];

@NgModule({
  declarations: [SkudoPrivateWrapperComponent],
  imports: [RouterModule.forRoot(routes), SkudoUiModule, SkudoHeaderModule, SkudoFooterModule],
  exports: [RouterModule],
})
export class PrivateRoutingModule {}
