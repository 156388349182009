<skudo-auth-wrapper [type]="type">
    <div id="allContainer">
        <div class="card-auth m-form">
            <h1 class="title"><span i18n>Esto es </span><span class="skudo">Skudo</span></h1>
            <p class="descript">
                <span i18n>Mete tus datos de acceso para iniciar sesión.</span>
            </p>
            <div class="form-group m-form__group m-form__group--md">
                <form (submit)="onSubmit($event)">
                    <div emma-label label="Email" class="m--margin-bottom-10">
                        <emma-input
                            type="email"
                            [(ngModel)]="email"
                            placeholder="email@dominio.com"
                            [required]="true"
                            name="email"
                        ></emma-input>
                    </div>
                    <div emma-label label="Contraseña">
                        <emma-input
                            type="password"
                            [(ngModel)]="password"
                            [required]="true"
                            name="password"
                        ></emma-input>
                    </div>
                    <p class="text-end">
                        <a class="m-link" target="_self" [routerLink]="ROUTE.FORGOT_PASSWORD" i18n
                            >Recuperar contraseña</a
                        >
                    </p>
                    <button
                        type="submit"
                        emmaButton
                        class="loginBtn"
                        color="skudodarkbluewhite"
                        [hasIcon]="true"
                        [isBlock]="true"
                        [loading]="loading.size"
                    >
                        <span>
                            <i class="las la-sign-in-alt fs-4" aria-hidden="true"></i>
                            <span class="m--font-bolder" style="font-size: 1.15rem" i18n>Identificarme</span>
                        </span>
                    </button>
                </form>
            </div>
            <div class="form-group m-form__group m-form__group--md">
                <div class="emma-form-social-login">
                    <span i18n>o</span>
                </div>
            </div>
            <div class="form-group m-form__group m-form__group--md">
                <div class="d-flex justify-content-center">
                    <div
                        id="g_id_onload"
                        data-client_id="73280845505-04t8dnopknarhiekp75hp02oamhai2l5.apps.googleusercontent.com"
                        data-context="signin"
                        data-ux_mode="redirect"
                        [attr.data-login_uri]="googleCallbackUrl"
                        data-auto_prompt="false"
                    ></div>

                    <div
                        class="g_id_signin"
                        data-type="standard"
                        data-shape="rectangular"
                        data-theme="outline"
                        data-text="signin_with"
                        [attr.data-locale]="googleButtonLocale"
                        data-size="large"
                        data-logo_alignment="center"
                        data-width="400"
                    ></div>
                </div>
            </div>
            <div class="emma-form-footer">
                <div class="m-login__desc">
                    <span i18n>¿No tienes cuenta?</span>
                    <a
                        [routerLink]="ROUTE.REGISTER"
                        id="m_login_signup"
                        class="m-link m-link--brand m-login__account-link"
                        i18n
                    >
                        Regístrate
                    </a>
                </div>
            </div>
        </div>
        <footer><span i18n>Skudo, parte de </span><strong>ARKANA</strong></footer>
    </div>
</skudo-auth-wrapper>
