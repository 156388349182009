import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EMMALoadingModule } from '@emma-components/emma-loading.module';
import { EMMAPortletModule } from '@emma-components/emma-portlet.module';
import { EMMASelectModule } from '@emma-components/forms/emma-select.module';
import { SkudoBaseChartComponent } from './skudo-base-chart/skudo-base-chart.component';
import { SkudoLineSeriesChartComponent } from './skudo-line-series-chart/skudo-line-series-chart.component';

@NgModule({
  imports: [CommonModule, FormsModule, EMMAPortletModule, EMMALoadingModule, EMMASelectModule],
  exports: [SkudoLineSeriesChartComponent],
  declarations: [SkudoLineSeriesChartComponent, SkudoBaseChartComponent],
})
export class SkudoChartModule {}
