import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';

import { EMMAModalComponent } from './emma-modal.component';

@NgModule({
  imports: [CommonModule, EMMADirectivesModule, FormsModule],
  declarations: [EMMAModalComponent],
  exports: [EMMAModalComponent],
})
export class EMMAModalModule {}
