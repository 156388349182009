/* eslint-disable class-methods-use-this */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'skudo-script-injector-statuspage',
  template: '',
})
export class ScriptInjectorStatuspageComponent implements OnInit {
  static inject(): void {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://hschl0m3dckh.statuspage.io/embed/script.js';
    script.setAttribute('crossorigin', '*');
    document.body.appendChild(script);
  }

  ngOnInit(): void {
    setTimeout(() => ScriptInjectorStatuspageComponent.inject(), 100);
  }
}
