import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { apiFeedback } from '@platform/helpers/api-feedback.helper';
import { AuthenticationService } from '@platform/services/authentication.service';
import { ToastNotificationsService } from '@platform/services/toast-notifications.service';
import { UserService } from '@skudo/services/user.service';
import { STATUS } from 'emma-common-ts';

@Component({
  selector: 'skudo-validate-account-page',
  template: '',
})
export class SkudoValidateAccountComponent implements OnInit {
  loading = new Set<string>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    public notifications: ToastNotificationsService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    const authToken = this.activatedRoute.snapshot.queryParams['verifyEmail'];
    this.userService
      .activateAccount(authToken)
      .pipe(apiFeedback('validateAccount', this.notifications, this.loading))
      .subscribe({
        next: (res) => {
          if (res.status === STATUS.SUCCESS) {
            this.notifications.setSuccess($localize`Tu cuenta ha sido activada correctamente.`);
          }
          this.authService.logout();
          this.router.navigateByUrl('/').catch(console.error);
        },
        error: () => {
          this.authService.logout();
          this.router.navigateByUrl('/').catch(console.error);
        },
      });
  }
}
