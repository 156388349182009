import { AfterViewInit, Component, OnInit } from '@angular/core';
import { apiFeedback } from '@platform/helpers/api-feedback.helper';
import { OnUnsubscribe } from '@platform/helpers/on-unsubscribe.class';
import { DateChangeService } from '@platform/services/date-change.service';
import { CurrentAccountService } from '@skudo/services/current-account.service';
import { MonitoringChangeService } from '@skudo/services/monitoring-change.service';
import { FinalRouteService } from '@platform/services/final-route.service';
import { UserService } from '@skudo/services/user.service';
import { DateRange } from 'emma-common-ts';
import { AccountUser, ROUTE, User } from 'emma-common-ts/skudo';
import { takeUntil } from 'rxjs';

declare let mLayout: { initHeader: () => unknown };
@Component({
  selector: 'skudo-header',
  templateUrl: './skudo-header.component.html',
})
export class SkudoHeaderComponent extends OnUnsubscribe implements OnInit, AfterViewInit {
  ROUTE = ROUTE;
  isDateSelectorVisible = true;
  isMonitoringVisible = true;
  userAccounts: AccountUser[] = [];
  currentAccount?: AccountUser;
  currentAccountId?: number;
  loading = new Set<string>();
  user?: User;

  constructor(
    private dateChangeService: DateChangeService,
    private currentAccountService: CurrentAccountService,
    private userService: UserService,
    private monitoringChangeService: MonitoringChangeService,
    private finalRouteService: FinalRouteService
  ) {
    super();
    this.finalRouteService.dataMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      this.isMonitoringVisible = Boolean(data.isMonitoringVisible);
    });
  }
  ngOnInit(): void {
    this.currentAccountService.$currentAccount.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account) => {
      this.currentAccount = account;
      this.currentAccountId = account?.id;
      this.updateAccountSelector();
    });
    this.loading.add('user');
    this.userService
      .getCurrentUser()
      .pipe(apiFeedback('user', undefined, this.loading), takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        this.user = user;
        if (user && this.currentAccount) {
          this.userAccounts = user.accounts;
          this.updateAccountSelector();
        }
      });
  }

  ngAfterViewInit(): void {
    mLayout?.initHeader();
  }

  updateAccountSelector() {
    //TODO: menú lateral
  }
  changeDate(dateRange: DateRange): void {
    this.dateChangeService.changeDate(dateRange);
  }
  changeUserAccount(accountId: number) {
    const account = this.userAccounts.find((acc) => acc.id === accountId);
    if (account) {
      this.currentAccountService.changeCurrentAccount(account);
    } else throw new Error(`Not exist account with id ${accountId}`);
  }
  changeMonitoring(monitoring: boolean): void {
    this.monitoringChangeService.changeMonitoring(monitoring);
  }
}
