import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EMMAFormModule } from '@emma-components/forms/emma-form.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, FormsModule, EMMAFormModule],
  exports: [EMMAFormModule],
  providers: [],
})
export class SkudoFormsModule {}
