import { ErrorHandler } from '@angular/core';

export class PlatformErrorHandler implements ErrorHandler {
  // eslint-disable-next-line class-methods-use-this
  handleError(error: Error) {
    console.error(error?.message || error, { error });
    if (error?.message && /ChunkLoadError:.*failed./i.test(error?.message)) {
      window.location.reload();
    }
  }
}
