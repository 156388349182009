import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EMMAUIModule } from '@emma-components/emmaui.module';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollTopComponent } from '@platform/components/scroll-top/scroll-top.component';
import { SkudoChartModule } from '../charts/skudo-charts.module';
import { SkudoFormsModule } from './forms/skudo-forms.module';

@NgModule({
  declarations: [ScrollTopComponent],
  imports: [
    NgbModule,
    CommonModule,
    FormsModule,
    SkudoFormsModule,
    EMMAUIModule,
    EMMADirectivesModule,
    SkudoChartModule,
  ],
  exports: [EMMAUIModule, EMMADirectivesModule, SkudoFormsModule, ScrollTopComponent, SkudoChartModule],
  providers: [],
})
export class SkudoUiModule {}
