import { Component } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { getGitInfo, GitInfo } from '@platform/helpers/get-git-info.helper';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeEs, 'es');

@Component({
  selector: 'skudo-footer',
  templateUrl: './skudo-footer.component.html',
})
export class SkudoFooterComponent {
  public currentYear: Date = new Date();
  public gitInfo: GitInfo;
  constructor() {
    this.gitInfo = getGitInfo();
  }
}
