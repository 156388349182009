import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthenticationService } from '@platform/services/authentication.service';
import { BackToLoginService } from '@platform/services/back-to-login.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class ApiAuthInterceptor implements HttpInterceptor {
  constructor(
    protected backToLoginService: BackToLoginService,
    protected authService: AuthenticationService,
    protected router: Router
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          this.apiAuthCheck(error);
        }
        return throwError(() => error);
      })
    );
  }

  private apiAuthCheck(response: HttpErrorResponse) {
    if ([401, 403].includes(response.status)) {
      this.authService.logout();
      this.backToLoginService
        .backToLogin({ from: this.router.url, endpoint: response.url })
        .catch(console.error);
      return false;
    }
    return true;
  }
}
