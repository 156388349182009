import { TIME_GROUP } from 'emma-common-ts';

export const getTimeGroupLabel = (timeGroup: TIME_GROUP): string => {
  switch (timeGroup) {
    case TIME_GROUP.WEEK:
      return $localize`Semanas`;
    case TIME_GROUP.MONTH:
      return $localize`Meses`;
    case TIME_GROUP.DAY:
      return $localize`Días`;
    default:
      return '';
  }
};
