<ng-container *ngIf="!removeOnEmpty || label">
    <label
        [ngClass]="{
            disabled: innerDisabled,
            'd-none': hiddenLabel
        }"
    >
        {{ label }} <span *ngIf="labelSuffix">({{ labelSuffix }})</span>
        <emma-info-icon
            [helpText]="helpText"
            [helpLink]="helpLink"
            [helpLinkLabel]="helpLinkLabel"
        ></emma-info-icon>
    </label>
    <span
        *ngIf="innerRequired && !innerDisabled"
        [ngClass]="{
            'required-label': true,
            'd-none': hiddenLabel
        }"
        i18n
    >
        Obligatorio
    </span>
</ng-container>
<ng-content></ng-content>
