import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@platform/services/authentication.service';

@Component({
  selector: 'skudo-logout-page',
  template: '',
})
export class SkudoLogoutComponent implements OnInit {
  constructor(private router: Router, private authService: AuthenticationService) {}

  ngOnInit(): void {
    this.authService.logout();
    this.router.navigateByUrl('/').catch(console.error);
  }
}
