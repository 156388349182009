import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SkudoUiModule } from '../skudo-ui.module';
import { SkudoHeaderMenuComponent } from './skudo-header-menu/skudo-header-menu.component';
import { SkudoHeaderComponent } from './skudo-header.component';
import { SkudoUserMenuComponent } from './skudo-user-menu/skudo-user-menu.component';

@NgModule({
  declarations: [SkudoHeaderComponent, SkudoHeaderMenuComponent, SkudoUserMenuComponent],
  imports: [CommonModule, FormsModule, RouterModule, SkudoUiModule],
  exports: [SkudoHeaderComponent],
})
export class SkudoHeaderModule {}
