import { Component, OnInit } from '@angular/core';
import { OnUnsubscribe } from '@platform/helpers/on-unsubscribe.class';
import { AuthenticationService } from '@platform/services/authentication.service';
import { UserService } from '@skudo/services/user.service';
import { User } from 'emma-common-ts/skudo';
import { takeUntil } from 'rxjs';

@Component({
  templateUrl: './welcome-page.component.html',
})
export class WelcomePageComponent extends OnUnsubscribe implements OnInit {
  user: User | undefined;
  constructor(private userService: UserService, private authService: AuthenticationService) {
    super();
    this.user = this.userService.getCurrentUser().getValue();
  }

  ngOnInit(): void {
    this.authService.logout$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => (this.user = this.userService.getCurrentUser().getValue()));
  }

  logout() {
    this.authService.logout();
  }
}
