import { HttpErrorResponse } from '@angular/common/http';
import { getErrorFromResponse } from '@platform/helpers/response-error.helper';
import { showApiFeedbackMessage } from '@platform/i18n/api-errors.i18n';
import { ToastNotificationsService } from '@platform/services/toast-notifications.service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

const showFeedbackError = (notifications: ToastNotificationsService, res: any) => {
  const errorCode = res instanceof HttpErrorResponse ? res.error.code : res?.code;
  if (errorCode > 0) {
    showApiFeedbackMessage(notifications, errorCode);
  } else {
    const errorMessage = getErrorFromResponse(res);
    if (errorMessage) {
      notifications.setError(errorMessage);
    }
  }
};

export function apiFeedback<T>(
  key: string,
  notifications?: ToastNotificationsService,
  loading?: Set<string>
): (obs: Observable<T>) => Observable<T> {
  return (obs: Observable<T>) =>
    obs.pipe(
      tap((res) => {
        if (notifications) {
          showFeedbackError(notifications, res);
        }
        if (res && loading) {
          loading.delete(key);
        }
        return res;
      }),
      catchError((error) => {
        if (notifications) {
          showFeedbackError(notifications, error);
        }
        if (loading) {
          loading.delete(key);
        }
        return throwError(() => error);
      })
    );
}
