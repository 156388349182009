<div [ngClass]="_containerClass">
    <div
        [ngClass]="{
            'emma-time-group-picker--drop': true
        }"
        ngbDropdown
        #drop="ngbDropdown"
    >
        <button [ngClass]="_buttonClass" [disabled]="disabled" (blur)="onBlur()" ngbDropdownToggle>
            <i *ngIf="icon" [class]="'la la-' + icon" aria-hidden="true"></i>
            <span *ngIf="_prefix" class="emma-time-group-picker--button--prefix">{{ _prefix }}</span>
            <span *ngIf="_label" class="emma-time-group-picker--button--label">{{ _label }}</span>
            <span *ngIf="!_label" class="emma-time-group-picker--button--placeholder">{{ placeholder }}</span>
        </button>
        <div ngbDropdownMenu class="emma-time-group-picker--menu">
            <button
                [disabled]="disabled"
                [ngClass]="{
                    active: timeGroup === TIME_GROUP.DAY
                }"
                ngbDropdownItem
                (click)="timeGroupChange(TIME_GROUP.DAY)"
                (blur)="onBlur()"
            >
                <i class="flaticon-calendar-2" aria-hidden="true"></i>
                <ng-container i18n>Días</ng-container>
            </button>
            <button
                [disabled]="disabled"
                [ngClass]="{
                    active: timeGroup === TIME_GROUP.WEEK
                }"
                ngbDropdownItem
                (click)="timeGroupChange(TIME_GROUP.WEEK)"
                (blur)="onBlur()"
            >
                <i class="flaticon-calendar" aria-hidden="true"></i>
                <ng-container i18n>Semanas</ng-container>
            </button>
            <button
                [disabled]="disabled"
                [ngClass]="{
                    active: timeGroup === TIME_GROUP.MONTH
                }"
                ngbDropdownItem
                (click)="timeGroupChange(TIME_GROUP.MONTH)"
                (blur)="onBlur()"
            >
                <i class="flaticon-calendar-3" aria-hidden="true"></i>
                <ng-container i18n>Meses</ng-container>
            </button>
        </div>
    </div>
</div>
