import { Injectable } from '@angular/core';
import { AccountUser } from 'emma-common-ts/skudo';
import { BehaviorSubject } from 'rxjs';

import { LOCAL_STORAGE_KEY, LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class CurrentAccountService {
  $currentAccount: BehaviorSubject<AccountUser | undefined>;

  constructor(private localStorageService: LocalStorageService) {
    const currentAccount = this.localStorageService.get<AccountUser>(LOCAL_STORAGE_KEY.CURRENT_ACCOUNT);
    this.$currentAccount = new BehaviorSubject(currentAccount);
  }

  changeCurrentAccount(currentAccount: AccountUser) {
    this.localStorageService.set(LOCAL_STORAGE_KEY.CURRENT_ACCOUNT, currentAccount);
    this.$currentAccount.next(currentAccount);
  }

  /**
   * Recupera los datos guardados de la app en el LocalStorage
   * @returns {any} Representación json de los datos de la app
   */
  getCurrentAccount(): AccountUser | undefined {
    return this.$currentAccount.getValue();
  }
}
