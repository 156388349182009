import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';

import { EMMAInfoIconComponent } from './emma-info-icon.component';

@NgModule({
  imports: [CommonModule, EMMADirectivesModule, NgbModule],
  exports: [EMMAInfoIconComponent, NgbModule],
  declarations: [EMMAInfoIconComponent],
})
export class EMMAInfoIconModule {}
