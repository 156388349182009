import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MonitoringChangeService {
  private $monitoringChange: BehaviorSubject<boolean>;

  constructor() {
    this.$monitoringChange = new BehaviorSubject<boolean>(false);
  }

  getChangeEmitter() {
    return this.$monitoringChange;
  }
  getCurrentMonitoring() {
    return this.$monitoringChange.getValue();
  }
  forceUpdate() {
    this.$monitoringChange.next(this.$monitoringChange.getValue());
  }
  changeMonitoring(newMonitoring: boolean) {
    if (newMonitoring !== this.$monitoringChange.value) {
      console.log('change monitoring', newMonitoring);
      this.$monitoringChange.next(newMonitoring);
    }
  }
}
