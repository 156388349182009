<!-- begin::Header -->
<header
    class="m-grid__item m-header"
    data-minimize="minimize"
    data-minimize-offset="200"
    data-minimize-mobile-offset="200"
>
    <div class="m-header__top">
        <div class="m-container m-container--responsive m-container--full-height m-page__container">
            <div class="m-stack m-stack--ver m-stack--desktop">
                <div class="m-stack__item m-brand">
                    <div class="m-stack m-stack--ver m-stack--general m-stack--inline">
                        <div class="m-stack__item m-stack__item--middle m-brand__logo">
                            <a [routerLink]="ROUTE.ROOT" class="m-brand__logo-wrapper">
                                <img
                                    id="Skudo-Logo"
                                    class="skudo-logo"
                                    src="assets/media/skudo/img/logo/Skudo-Isotipo.svg"
                                    alt="Skudo"
                                    i18n-alt
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <!--<div class="m-stack__item m-stack__item--middle m-brand__tools">
                    <emma-select
                        selectClass="ng-select-btn-emmadarkblue ng-select--pill ng-select-drop-auto-width ng-select-sm selectAppPicker"
                        [items]="userAccounts"
                        [(ngModel)]="currentAccountId"
                        (ngModelChange)="changeUserAccount($event)"
                        valueKey="id"
                        labelKey="name"
                        notFoundText="Cuenta no encontrada"
                        [searchable]="true"
                        [isFullWidth]="false"
                        placeholder="Cargando..."
                        i18n-placeholder
                        i18n-notFoundText
                    ></emma-select>
                </div>-->
                <div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
                    <div id="m_header_topbar" class="m-topbar m-stack m-stack--ver m-stack--general">
                        <div class="m-topbar__action">
                            <div *ngIf="isMonitoringVisible" class="m-topbar__action--btntext">
                                <emma-switch
                                    (checkedChange)="changeMonitoring($event)"
                                    size="small"
                                ></emma-switch>
                                <span i18n>Ver monitorización</span>
                            </div>
                        </div>
                        <emma-date-range-group-picker
                            *ngIf="isDateSelectorVisible"
                            ($change)="changeDate($event)"
                            class="topbar-location"
                            color="skudoorange"
                        ></emma-date-range-group-picker>
                        <skudo-user-menu [user]="user"></skudo-user-menu>
                        <div class="m-stack__item m-topbar__nav-wrapper"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="m-header__bottom">
        <div class="m-container m-container--responsive m-container--full-height m-page__container">
            <div class="m-stack m-stack--ver m-stack--desktop">
                <div class="m-stack__item m-stack__item--middle m-stack__item--fluid">
                    <button
                        class="m-aside-header-menu-mobile-close m-aside-header-menu-mobile-close--skin-light"
                        id="m_aside_header_menu_mobile_close_btn"
                    >
                        <i class="la la-close" aria-hidden="true"></i>
                    </button>
                    <skudo-header-menu
                        id="m_header_menu"
                        class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas m-header-menu--skin-dark m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-light m-aside-header-menu-mobile--submenu-skin-light"
                    ></skudo-header-menu>
                </div>
            </div>
        </div>
    </div>
</header>
