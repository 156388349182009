import { Directive, TemplateRef } from '@angular/core';

// tslint:disable: max-classes-per-file
@Directive({ selector: '[emma-switch-sibling-tmp]' })
export class EMMASwitchSiblingTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[emma-switch-label-tmp]' })
export class EMMASwitchLabelTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}
