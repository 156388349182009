import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EMMADropdownModule } from '@emma-components/emma-dropdown.module';
import { EMMAModalModule } from '@emma-components/modals/emma-modal.module';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';
import { QuillModule } from 'ngx-quill';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EMMAModalTextCheckModule } from '../../modals/emma-text-check-modal.module';
import { EMMAFormElementModule } from '../emma-form-element.module';
import { EMMAInputModule } from '../emma-input.module';
import { EMMASelectModule } from '../emma-select.module';
import { EMMARichEditorInsertImageTagModalComponent } from './emma-rich-editor-insert-image-tag-modal.component';
import { EMMARichEditorComponent } from './emma-rich-editor.component';

@NgModule({
  imports: [
    CommonModule,
    EMMADirectivesModule,
    EMMADropdownModule,
    EMMAFormElementModule,
    EMMASelectModule,
    EMMAModalModule,
    EMMAInputModule,
    FormsModule,
    EMMAModalTextCheckModule,
    NgbModule,
    QuillModule.forRoot(),
  ],
  declarations: [EMMARichEditorComponent, EMMARichEditorInsertImageTagModalComponent],
  exports: [EMMARichEditorComponent, EMMARichEditorInsertImageTagModalComponent],
})
export class EMMARichEditorModule {}
