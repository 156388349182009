<div class="m-grid m-grid--hor m-grid--root m-page">
  <skudo-header></skudo-header>
  <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
    <div
      class="m-grid__item m-grid__item--fluid m-grid m-grid--ver m-container m-container--responsive m-container--xxl m-page__container"
    >
      <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <skudo-footer></skudo-footer>
</div>