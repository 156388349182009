import { Component, EventEmitter, forwardRef, Input, OnChanges, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { generateClassesArray } from '@emma-helpers/emma-utils';
import { getTimeGroupLabel } from '@emma-i18n/date-range.i18n';
import { TIME_GROUP } from 'emma-common-ts';
import { noop } from 'lodash';

import { EMMAFormElementComponent } from './emma-form-element.component';

const EMMA_TIME_GROUP_PICKER_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EMMATimeGroupPickerComponent),
  multi: true,
};

const EMMA_TIME_GROUP_PICKER_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => EMMATimeGroupPickerComponent),
  multi: true,
};

@Component({
  selector: 'emma-time-group-picker',
  templateUrl: './emma-time-group-picker.component.html',
  providers: [
    EMMA_TIME_GROUP_PICKER_CONTROL_VALUE_ACCESSOR,
    EMMA_TIME_GROUP_PICKER_VALIDATOR,
    {
      provide: EMMAFormElementComponent,
      useExisting: EMMATimeGroupPickerComponent,
    },
  ],
})
export class EMMATimeGroupPickerComponent
  extends EMMAFormElementComponent
  implements OnChanges, ControlValueAccessor, Validator
{
  @Input() icon = '';
  /** Size */
  @Input() reducedSize = false;
  @Input() override placeholder = '';
  @Input() prefix = $localize`Agrupar por: `;
  _prefix = '';
  @Input() label = '';
  _label = '';

  @Input() get timeGroup() {
    return this._timeGroup;
  }
  set timeGroup(timeGroup: TIME_GROUP) {
    this._timeGroup = timeGroup;
    this.$change.emit(timeGroup);
  }
  private _timeGroup: TIME_GROUP = TIME_GROUP.DAY;
  TIME_GROUP = TIME_GROUP;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('class') override containerClass = '';
  override _containerClass: Array<string> = [];
  @Input() buttonClass = 'btn-metal';
  _buttonClass: Array<string> = [];

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: string) => void = noop;

  @Output() override $change = new EventEmitter<TIME_GROUP>();
  @Output() override $blur = new EventEmitter<TIME_GROUP>();

  timeGroupChange(timeGroup: TIME_GROUP) {
    this.timeGroup = timeGroup;
    this.onChangeCallback(this.timeGroup);
    this.$change.emit(this.timeGroup);
  }

  updateLabel() {
    if (this.label) {
      this._label = this.label;
    } else {
      this._label = getTimeGroupLabel(this.timeGroup);
    }
    this._prefix = this.prefix || '';
  }

  override updateClasses() {
    this._containerClass = generateClassesArray('emma-time-group-picker', this.containerClass);
    this._buttonClass = generateClassesArray(
      'emma-time-group-picker--button m-btn btn',
      this.reducedSize ? 'btn-sm' : '',
      this.buttonClass
    );
  }

  override ngOnChanges(): void {
    this.updateLabel();
    this.updateClasses();
  }

  onBlur() {
    this.onTouchedCallback();
    this.$blur.emit(this.timeGroup);
  }

  // From ControlValueAccessor interface
  writeValue(timeGroup: TIME_GROUP) {
    this._timeGroup = timeGroup;
    this.updateLabel();
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
