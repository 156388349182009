import { SIZE_UNITS } from '@emma-helpers/emma-validators.helper';
import { get } from 'lodash';

import { formatNumber } from './format.helper';

export interface FileSizeValidatorOptions {
  maxSize: number;
  minSize: number;
  maxSizeUnit: SIZE_UNITS;
  minSizeUnit: SIZE_UNITS;
}
export const isFileSizeValid =
  ({
    maxSize = Infinity,
    minSize = 0,
    maxSizeUnit = SIZE_UNITS.B,
    minSizeUnit = SIZE_UNITS.B,
  }: Partial<FileSizeValidatorOptions>) =>
  (size: number): boolean => {
    if (maxSize < Infinity) {
      const maxSizeBytes = maxSize * maxSizeUnit;
      if (maxSizeBytes < size) {
        return false;
      }
    }
    if (minSize > 0) {
      const minSizeBytes = minSize * minSizeUnit;
      if (minSizeBytes > size) {
        return false;
      }
    }
    return true;
  };

export const getHumanReadableFileSize = (size: number): string => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const strSize = formatNumber(size / Math.pow(1024, i));
  return strSize + ' ' + ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB'][i];
};

export const getFileContentHead = (file: File, limit: number): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (ev) => resolve(get(ev, 'target.result', '').substring(0, limit));
    reader.onerror = (err) => reject(err);
    reader.readAsText(file, 'UTF-8');
  });
};

export const getCsvPreview = (
  content: string,
  limitElements: number | undefined,
  splitChars?: string[] // [',', ';']
): string => {
  const elements = content.split('\n');
  const hasMore = limitElements === undefined ? false : elements.length > limitElements;
  const preview = elements
    .slice(0, limitElements)
    .map((str) => {
      if (splitChars) {
        for (const char of splitChars) {
          if (str.includes(char)) {
            return str.substr(0, str.indexOf(char)).trim();
          }
        }
      }
      return str.trim();
    })
    .join('\n');
  return `${preview}${hasMore ? '\n...' : ''}`;
};

export const getFileExtension = (file: File): string => {
  const name = file.name;
  return name.substr(name.lastIndexOf('.') + 1);
};

export const hasFileExtension = (file: File, extensions: string[]): boolean => {
  const ext = getFileExtension(file).toLowerCase();
  return extensions.includes(ext);
};

export const validateCsvIdentificatorsFile = async (file: File): Promise<void> => {
  if (!hasFileExtension(file, ['csv'])) {
    console.warn(`Invalid CSV file type ${file.type} with extension ${getFileExtension(file)}`);
    throw new Error('Not a CSV file');
  }
  const firstLine = await getFileContentHead(file, 10000).then((content) => content.split('\n')[0]);
  if (firstLine.includes(',') || firstLine.includes(';')) {
    console.warn('Invalid CSV file content, it starts with', firstLine);
    throw new Error('Invalid CSV file content format');
  }
};
