import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EMMAInputAutocompleteComponent } from './emma-input-autocomplete.component';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EMMADropdownModule } from '@emma-components/emma-dropdown.module';
import { EMMAInfoIconModule } from '@emma-components/emma-info-icon.module';
import { EMMAModalTextCheckModule } from '@emma-components/modals/emma-text-check-modal.module';
import { EMMAInputModule } from './emma-input.module';

@NgModule({
  declarations: [EMMAInputAutocompleteComponent],
  imports: [
    CommonModule,
    FormsModule,
    EMMADirectivesModule,
    NgbModule,
    EMMADropdownModule,
    EMMAInfoIconModule,
    EMMAModalTextCheckModule,
    EMMAInputModule,
  ],
  exports: [EMMAInputAutocompleteComponent],
})
export class EmmaInputAutocompleteModule {}
