export function isMenuActiveRoute(item: { route?: string; menu?: Array<{ route: string }> }): boolean {
  const path = window.location.pathname;
  const isActiveRoute = item.route ? path.startsWith(item.route) : false;
  let isActiveChildRoute;
  if (!isActiveRoute) {
    isActiveChildRoute = item.menu
      ?.filter((child) => child.route)
      .some((child) => path.startsWith(String(child.route)));
  }
  return Boolean(isActiveRoute || isActiveChildRoute);
}
