<ng-container class="emma-copy-to-clipboard">
    <button
        *ngIf="copyValue"
        #popover="ngbPopover"
        [class]="'emma-copy-to-clipboard--button ' + copyButtonClass"
        emmaButton
        [isCircle]="true"
        [hasIcon]="true"
        [onlyIcon]="true"
        [noBg]="true"
        color="brand"
        (click)="copyToClipboard()"
        ngbPopover="Copiado"
        placement="right"
        triggers="manual"
        container="body"
        i18n-ngbPopover
    >
        <i class="la la-clipboard" aria-hidden="true"></i>
    </button>
    <ng-content></ng-content>
</ng-container>
