import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { EMMAFormElementComponent } from './emma-form-element.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [EMMAFormElementComponent],
  exports: [EMMAFormElementComponent],
})
export class EMMAFormElementModule {}
