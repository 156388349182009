import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EMMADirectivesModule } from '../_directives/emma-directives.module';

import { EMMAHelpVideoComponent } from './emma-help-video.component';
import { EMMAModalModule } from './modals/emma-modal.module';
import { EMMAVideoEmbedModalComponent } from './modals/emma-video-embed-modal.component';

@NgModule({
  imports: [CommonModule, EMMADirectivesModule, EMMAModalModule, NgbModule],
  exports: [EMMAHelpVideoComponent, EMMAVideoEmbedModalComponent, NgbModule],
  declarations: [EMMAHelpVideoComponent, EMMAVideoEmbedModalComponent],
})
export class EMMAHelpVideoModule {}
