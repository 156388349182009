import { EventEmitter } from '@angular/core';
import { getGitInfo } from '@platform/helpers/get-git-info.helper';

export class LocalStorageService {
  /**
   * Emitted when there is a change in the local storage.
   * Requires a singleton instance of this service.
   */
  public changed: EventEmitter<string | void> = new EventEmitter();

  protected storageDriver: Storage;

  constructor(public storageIdKey = 'build') {
    this.storageDriver = window.sessionStorage;
    const { tag, hash } = getGitInfo();
    this.init(hash, tag);
  }

  public init(hash: string, tag: string | null): void {
    if (this.getStorageId() !== hash) {
      console.info(`Upgraded to ${tag ? tag + ' ' : ''}build ${hash}`);
      this.clear();
    }
    this.setStorageId(hash);
  }

  public get<T>(key: string): T | undefined {
    const value = this.storageDriver.getItem(key);
    if (value !== null) {
      return JSON.parse(value) as T;
    }
    return undefined;
  }

  public set<T = unknown>(key: string, value: T): void {
    this.storageDriver.setItem(key, JSON.stringify(value));
    this.changed.emit(key);
  }

  public remove(key: string): void {
    this.storageDriver.removeItem(key);
  }

  public clear(): void {
    this.storageDriver.clear();
    this.changed.emit();
  }

  private getStorageId(): string {
    return this.storageDriver.getItem(this.storageIdKey) || '';
  }
  private setStorageId(id: string): void {
    this.storageDriver.setItem(this.storageIdKey, id);
  }
}
