import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { cleanUpTempProps } from '@platform/helpers/api-body.helper';
import { AuthHeaders } from '@platform/helpers/auth-headers.class';
import { getApiBaseUrl } from '@platform/helpers/base-url.helper';
import { API_URL } from '@platform/providers/url.provider';
import { ApiChangeResponse, ApiResponse, objectToParams } from 'emma-common-ts';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';

interface ApiServiceOptions {
  headers?: HttpHeaders;
  forced?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ApiService {
  apiUrl: string;
  constructor(
    private http: HttpClient,
    @Inject(API_URL) apiUrl: string,
    @Inject(AuthHeaders) private authHeaders: AuthHeaders
  ) {
    this.apiUrl = getApiBaseUrl(apiUrl);
    if (!this.apiUrl) {
      throw new Error('API_URL is not defined');
    }
    if (this.apiUrl.endsWith('/')) {
      this.apiUrl = this.apiUrl.slice(0, -1);
    }
  }

  private getFinalUrl(url: string): string {
    if (url.startsWith('/')) {
      return `${this.apiUrl}${url}`;
    }
    return `${this.apiUrl}/${url}`;
  }

  private injectHeaders(options: ApiServiceOptions) {
    const headers = options.headers ?? this.authHeaders.get();
    return { ...options, headers };
  }

  get = <T = ApiResponse>(url: string, params: any = {}, options: ApiServiceOptions = {}): Observable<T> => {
    const join = url.includes('?') ? '&' : '?';
    const paramsStr = objectToParams(cleanUpTempProps(params));
    return this.http.get<T>(`${this.getFinalUrl(url)}${!isEmpty(paramsStr) ? join : ''}${paramsStr}`, {
      responseType: 'json',
      ...this.injectHeaders(options),
    });
  };
  put = <T = ApiChangeResponse>(
    url: string,
    body: any = {},
    options: ApiServiceOptions = {}
  ): Observable<T> => {
    return this.http.put<T>(this.getFinalUrl(url), cleanUpTempProps(body), this.injectHeaders(options));
  };
  post = <T = ApiChangeResponse>(
    url: string,
    body: any = {},
    options: ApiServiceOptions = {}
  ): Observable<T> => {
    return this.http.post<T>(this.getFinalUrl(url), cleanUpTempProps(body), this.injectHeaders(options));
  };
  delete = <T = ApiChangeResponse>(
    url: string,
    body: any = {},
    options: ApiServiceOptions = {}
  ): Observable<T> => {
    const join = url.includes('?') ? '&' : '?';
    return this.http.delete<T>(`${this.getFinalUrl(url)}${join}${objectToParams(cleanUpTempProps(body))}`, {
      ...this.injectHeaders(options),
    });
  };
  patch = <T = ApiChangeResponse>(
    url: string,
    body: any = {},
    options: ApiServiceOptions = {}
  ): Observable<T> => {
    return this.http.patch<T>(this.getFinalUrl(url), cleanUpTempProps(body), this.injectHeaders(options));
  };
  head = (url: string, options: ApiServiceOptions = {}) => {
    return this.http.head(this.getFinalUrl(url), this.injectHeaders(options));
  };
  options = (url: string, options: ApiServiceOptions = {}) => {
    return this.http.options(this.getFinalUrl(url), this.injectHeaders(options));
  };
}
