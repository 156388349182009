import { Injectable } from '@angular/core';
import { AccountUser } from 'emma-common-ts/skudo';
import { BehaviorSubject } from 'rxjs';

import { CurrentAccountService } from './current-account.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class AccountsService {
  $currentAccount: BehaviorSubject<AccountUser | undefined>;
  accounts: AccountUser[] = [];

  constructor(private currentAccountService: CurrentAccountService, private userService: UserService) {
    this.$currentAccount = this.currentAccountService.$currentAccount;
    this.userService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.accounts = user?.accounts || [];
        if (this.accounts && this.accounts.length) {
          const currentAccount = this.currentAccountService.$currentAccount.value;
          if (!currentAccount) {
            const account = this.accounts.find((account) => account.isDefault) || this.accounts[0];
            this.currentAccountService.changeCurrentAccount(account);
          }
        }
      }
    });
  }

  selectCurrentAccount(id: number): void {
    const account = this.accounts.find((account) => account.id === id);
    if (account) {
      this.currentAccountService.changeCurrentAccount(account);
    }
  }

  /**
   * Recupera los datos guardados de la app en el LocalStorage
   * @returns {any} Representación json de los datos de la app
   */
  getCurrentAccount(): AccountUser | undefined {
    return this.$currentAccount.getValue();
  }
}
