import { CommonModule, Location } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { PlatformNotificationFeedbackModule } from '@platform/components/platform-notification-feedback/platform-notification-feedback.module';
import type { RouterRoutes } from '@platform/types/router-route.model';
import { SkudoUiModule } from '@skudo/components/skudo-ui.module';
import { NoUserGuard } from '@skudo/guards/no-user.guard';
import { ScriptInjectorsModule } from '@skudo/script-injectors/script-injectors.module';
import { SkudoAuthWrapperComponent } from './auth-wrapper.component';
import { SkudoForgotPasswordPageComponent } from './forgot-password/forgot-password.component';
import { SkudoLoginPageComponent } from './login/login.component';
import { SkudoLogoutComponent } from './logout/logout.component';
import { SkudoRecoverPasswordPageComponent } from './recover-password/recover-password.component';
import { SkudoRegisterPageComponent } from './register/register.component';
import { SkudoValidateAccountComponent } from './validate-account/validate-account.component';

const routes: RouterRoutes = [
  {
    path: 'login',
    component: SkudoLoginPageComponent,
    canActivate: [NoUserGuard],
  },
  { path: 'logout', component: SkudoLogoutComponent },
  {
    path: 'register',
    component: SkudoRegisterPageComponent,
    canActivate: [NoUserGuard],
  },
  {
    path: 'forgot-password',
    component: SkudoForgotPasswordPageComponent,
    canActivate: [NoUserGuard],
  },
  {
    path: 'recover-password',
    component: SkudoRecoverPasswordPageComponent,
    canActivate: [NoUserGuard],
  },
  {
    path: 'validate-account',
    component: SkudoValidateAccountComponent,
    canActivate: [NoUserGuard],
  },
];
@NgModule({
  declarations: [
    SkudoAuthWrapperComponent,
    SkudoForgotPasswordPageComponent,
    SkudoForgotPasswordPageComponent,
    SkudoLoginPageComponent,
    SkudoLogoutComponent,
    SkudoRegisterPageComponent,
    SkudoRecoverPasswordPageComponent,
    SkudoValidateAccountComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    PlatformNotificationFeedbackModule,
    SkudoUiModule,
    HttpClientModule,
    ScriptInjectorsModule,
    RouterModule.forChild(routes),
  ],
  providers: [Location, RouterModule],
})
export class AuthRoutingModule {}
