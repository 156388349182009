/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';

const collectRouteData = (router: Router) => {
  let params = {};
  let data = {};
  let queryParams = {};
  const stack: ActivatedRouteSnapshot[] = [router.routerState.snapshot.root];
  while (stack.length > 0) {
    // tslint:disable-next-line: no-non-null-assertion
    const route = stack.pop()!;
    params = { ...params, ...route.params };
    data = { ...data, ...route.data };
    queryParams = { ...queryParams, ...route.queryParams };
    stack.push(...route.children);
  }
  return { params, data, queryParams };
};

@Injectable({
  providedIn: 'root',
})
export class FinalRouteService {
  paramMap = new Subject<Record<string, string>>();
  dataMap = new Subject<Record<string, any>>();
  queryParamMap = new Subject<Record<string, string>>();
  constructor(protected router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const { params, data, queryParams } = collectRouteData(this.router);
        this.paramMap.next(params);
        this.dataMap.next(data);
        this.queryParamMap.next(queryParams);
      }
    });
  }
}
