import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';

import { EMMAFormElementModule } from './emma-form-element.module';
import { EMMAInputModule } from './emma-input.module';
import { EMMAMultiInputComponent } from './emma-multi-input.component';

@NgModule({
  imports: [CommonModule, EMMADirectivesModule, EMMAFormElementModule, EMMAInputModule, FormsModule],
  declarations: [EMMAMultiInputComponent],
  exports: [EMMAMultiInputComponent],
})
export class EMMAMultiInputModule {}
