import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, Validator, ValidatorFn, Validators } from '@angular/forms';
import { OnUnsubscribe } from '@platform/helpers/on-unsubscribe.class';

@Component({
  selector: 'emma-form-element',
  template: '',
})
export class EMMAFormElementComponent extends OnUnsubscribe implements OnInit, OnChanges, Validator {
  @Input() name = '';
  @Input() autofocus = false;
  @Input() disabled = false;
  @Input() required: boolean | number = false;
  @Input() placeholder = '';
  @Input() readonly = false;
  @Input() loading = false;
  @Input() error: undefined | null | boolean | string;
  @Input() tabindex: number | undefined;

  // For Validator interface
  @Input() validateFn?: ValidatorFn;

  @Input() containerClass = '';
  _containerClass: Array<string> = [];

  @Output() $focus = new EventEmitter<any>();
  @Output() $blur = new EventEmitter<any>();
  @Output() $change = new EventEmitter<any>();
  @Output() $keydown = new EventEmitter<any>();
  @Output() $keyup = new EventEmitter<any>();
  @Output() $keypress = new EventEmitter<any>();
  @Output() $enter = new EventEmitter<any>();

  isHover = false;
  isFocus = false;

  ngOnInit(): void {
    this.updateClasses();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.updateClasses();
  }
  updateClasses(): void {
    this._containerClass = ['emma-input', this.containerClass];
  }

  // From Validator interface
  validate: ValidatorFn = (control: AbstractControl) => {
    const validators: ValidatorFn[] = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.validateFn) {
      validators.push(this.validateFn);
    }
    return validators.map((v) => v(control)).find((err) => err !== null) || null;
  };
}
