import {
  checkPasswordLength,
  checkPasswordLowercase,
  checkPasswordNumbers,
  checkPasswordSpecialChars,
  checkPasswordUppercase,
} from 'emma-common-ts';

export const getNotificationsPassword = (
  minPasswordLength: number,
  password: string,
  repeatPassword?: string
): string[] => {
  const notifications: string[] = [];
  if (repeatPassword && password !== repeatPassword) {
    notifications.push($localize`La nueva contraseña debe coincidir en ambos campos.`);
  }
  if (
    !checkPasswordUppercase(password) ||
    !checkPasswordLowercase(password) ||
    !checkPasswordNumbers(password) ||
    !checkPasswordSpecialChars(password)
  ) {
    notifications.push(
      $localize`La contraseña debe tener al menos una letra mayúscula, una letra mínuscula, un número y un símbolo de entre los siguientes: !@#$%^&*()_+¡¿?`
    );
  }
  if (!checkPasswordLength(password, minPasswordLength)) {
    notifications.push(
      $localize`La nueva contraseña debe tener un mínimo de ${minPasswordLength} caracteres.`
    );
  }
  return notifications;
};
