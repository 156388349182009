import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '@skudo/guards/auth.guard';

import { IndexPageComponent } from './index-page.component';

import type { RouterRoutes } from '@platform/types/router-route.model';
const routes: RouterRoutes = [
  {
    path: '',
    component: IndexPageComponent,
    canActivate: [AuthGuard],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [IndexPageComponent],
  declarations: [IndexPageComponent],
})
export class IndexRoutingModule {}
