import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { EMMADropdownComponent } from './emma-dropdown.component';
import { EMMAInputModule } from './forms/emma-input.module';

@NgModule({
  imports: [CommonModule, FormsModule, NgbModule, EMMADirectivesModule, EMMAInputModule],
  exports: [EMMADropdownComponent],
  declarations: [EMMADropdownComponent],
})
export class EMMADropdownModule {}
