import { Injectable } from '@angular/core';
import { LocalStorageService as PlatformLocalStorageService } from '@platform/services/local-storage.service';

export enum LOCAL_STORAGE_KEY {
  CURRENT_USER = 'currentUser',
  CURRENT_ACCOUNT = 'currentAccount',
  OFFER_DRAFT = 'offerDraft',
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService extends PlatformLocalStorageService {
  constructor() {
    super('skudoBuild');
  }
  override get<T>(key: LOCAL_STORAGE_KEY): T | undefined {
    return super.get(key);
  }
  override set<T>(key: LOCAL_STORAGE_KEY, value: T): void {
    return super.set(key, value);
  }
  override remove(key: LOCAL_STORAGE_KEY): void {
    return super.remove(key);
  }
}
