import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';

import { PlatformNotificationFeedbackComponent } from './platform-notification-feedback.component';
import {
  PlatformNotificationActionsRightTemplateDirective,
  PlatformNotificationDismissButtonTemplateDirective,
} from './platform-notification-feedback.directive';

@NgModule({
  declarations: [
    PlatformNotificationFeedbackComponent,
    PlatformNotificationActionsRightTemplateDirective,
    PlatformNotificationDismissButtonTemplateDirective,
  ],
  imports: [CommonModule, EMMADirectivesModule],
  exports: [
    PlatformNotificationFeedbackComponent,
    PlatformNotificationActionsRightTemplateDirective,
    PlatformNotificationDismissButtonTemplateDirective,
  ],
})
export class PlatformNotificationFeedbackModule {}
