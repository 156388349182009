import { NgModule } from '@angular/core';

import { FormatCasePipe } from './format-case.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { FormatFilenamePipe } from './format-filename.pipe';
import { FormatMinutesTimePipe } from './format-minutes-time.pipe';
import { FormatNumberPipe } from './format-number.pipe';
import { FormatStringPipe } from './format-string.pipe';
import { RoundNumberPipe } from './round-number.pipe';

@NgModule({
  imports: [],
  declarations: [
    FormatNumberPipe,
    FormatStringPipe,
    FormatFilenamePipe,
    FormatCasePipe,
    FormatDatePipe,
    FormatMinutesTimePipe,
    RoundNumberPipe,
  ],
  exports: [
    FormatNumberPipe,
    FormatStringPipe,
    FormatFilenamePipe,
    FormatCasePipe,
    FormatDatePipe,
    FormatMinutesTimePipe,
    RoundNumberPipe,
  ],
})
export class EMMAPipesModule {}
