import { Component, Inject, LOCALE_ID } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthenticationService } from '@platform/services/authentication.service';
import { loadDayjsLocale } from '@platform/services/locale.service';
import { UserService } from './services/user.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'skudo-app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'skudo';
  isLoading = true;
  isInitialLoad = true;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private locale: string,
    private authService: AuthenticationService,
    private userService: UserService,
    private router: Router
  ) {
    this.document.documentElement.lang = this.locale;
    loadDayjsLocale(this.locale);
    this.authService.logout$.subscribe(() => this.userService.removeUser());
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationStart) {
        this.isLoading = true;
      } else if (route instanceof NavigationEnd) {
        // Solo se ejecuta en la navegación de páginas fuera de la carga inicial
        if (!this.isInitialLoad) {
          this.isLoading = false;
        }
      }
    });
  }
}
