import { getCountries } from 'emma-common-ts';

export const DEFAULT_CURRENCY = 'EUR';

const currencySymbols: { [key: string]: string } = {};

const initializeCurrencySymbols = async () => {
  const countriesJson = await getCountries();
  countriesJson.forEach((country) => {
    if (country.currencies) {
      country.currencies.forEach((currency) => {
        if (currency.code && !currencySymbols[currency.code]) {
          currencySymbols[currency.code] = currency.symbol ?? '';
        }
      });
    }
  });
};

initializeCurrencySymbols();

export const isCurrencyCode = (value: string): boolean => {
  return Boolean(currencySymbols[value]);
};

export const getCurrencySymbol = (currencyCode: string) => {
  return currencySymbols[currencyCode] || currencySymbols[DEFAULT_CURRENCY];
};
