<skudo-auth-wrapper [type]="type">
    <div id="allContainer">
        <div class="card-auth m-form">
            <h1 class="title"><span i18n>Esto es </span><span class="skudo">Skudo</span></h1>
            <p class="descript">
                <span i18n>Introduce tu dirección de correo para restablecer la contraseña de tu cuenta.</span>
            </p>
            <div class="form-group m-form__group m-form__group--md">
                <div emma-label label="Email de la cuenta" class="m--margin-bottom-20">
                    <emma-input
                      type="email"
                      inputmode="email"
                      placeholder="email@dominio.com"
                      [(ngModel)]="emailText"
                      [required]="true"
                      name="email"
                      autocomplete="off"
                      ></emma-input>
                </div>
                <button 
                    emmaButton
                    sizeButton="lg"
                    color="skudodarkbluewhite"
                    [isBlock]="true"
                    [type]="type"
                    [loading]="loading.size"
                    [disabled]="loading.size || !isValid()"
                    (click)="forgotPassword()"
                >
                    <strong i18n>Restablecer contraseña</strong>
                </button>
            </div>
            <p class="lead">
                <span i18n>¿Quieres iniciar sesión? </span>
                <a class="m-link" target="_self" href="/login/" i18n>Identifícate</a>
            </p>
        </div>
        <footer>
            <span i18n>Skudo, parte de </span><strong>ARKANA</strong>
        </footer>
    </div>
</skudo-auth-wrapper>
