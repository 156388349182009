export const cleanAuthTokenFromPath = (url: string) => {
  const [path, query] = url.split('?');
  const urlSearchParams = new URLSearchParams(query);
  if (urlSearchParams.has('authToken')) {
    urlSearchParams.delete('authToken');
    const modifiedQueryParams = urlSearchParams.toString();
    return path + (urlSearchParams.toString().length > 0 ? `?${modifiedQueryParams}` : '');
  }
  return url;
};
