import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { EMMACopyToClipboardComponent } from './emma-copy-to-clipboard.component';

@NgModule({
  declarations: [EMMACopyToClipboardComponent],
  imports: [CommonModule, EMMADirectivesModule, NgbModule],
  exports: [EMMACopyToClipboardComponent],
})
export class EMMACopyToClipboardModule {}
