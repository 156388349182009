import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import type { RouterRoutes } from '@platform/types/router-route.model';
import { SkudoUiModule } from '@skudo/components/skudo-ui.module';
import { WelcomePageComponent } from './welcome/welcome-page/welcome-page.component';
const routes: RouterRoutes = [
  { path: 'welcome', component: WelcomePageComponent, pathMatch: 'full' },
  {
    path: 'ui-components',
    loadChildren: () =>
      import('@skudo/pages/public/ui-components/ui-components-page.module').then(
        (m) => m.UIComponentsPageModule
      ),
  },
];

@NgModule({
  imports: [SkudoUiModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: [WelcomePageComponent],
})
export class PublicRoutingModule {}
