import { AbstractControl } from '@angular/forms';
import { LabelValue, regexCardCVC, regexCardNumber, regexParamUrl } from 'emma-common-ts';
import { isValidJson, isValidJsonWithParams } from 'emma-common-ts/emma';

import { isNil } from 'lodash';
import { isFileSizeValid } from './file.helper';
import {
  isCertificateFingerPrint,
  isEmmaUrl,
  isHexColor,
  isHostname,
  isUrl,
  isUrlSuffix,
  isWeburl,
} from './is-function.helper';

export enum SIZE_UNITS {
  B = 1,
  KB = 1024,
  MB = 1048576,
  GB = 1073741824,
}

/**
 * Number inside a range
 */
export const validatorNumberRange =
  (maxValue: number, minValue = 0) =>
  (control: AbstractControl) => {
    const numberValue = Number(control.value);
    const err = {
      numberRangeError: {
        given: control.value,
        max: maxValue,
        min: minValue,
        isNumber: !isNaN(numberValue),
      },
    };

    if (isNaN(numberValue)) {
      return err;
    }
    return control.value > +maxValue || control.value < +minValue ? err : null;
  };

/**
 * @description
 * Number of elements in a collection-like (Array, Set, String, etc)
 */
export const validatorCollectionLength =
  (maxValue: number, minValue = 0) =>
  (control: AbstractControl) => {
    if (control.value) {
      const err = {
        collectionLengthError: {
          given: control.value,
          max: maxValue,
          min: minValue,
        },
      };

      if (!('length' in control.value || 'size' in control.value)) {
        return err;
      }
      const size = 'length' in control.value ? control.value.length : control.value.size;
      return size > +maxValue || size < +minValue ? err : null;
    }
    return null;
  };

/**
 * Looks like a certificate
 */
export const validatorCertificateContent = (control: AbstractControl) => {
  if (control.value) {
    return 'string' === typeof control.value && control.value.startsWith('-----BEGIN')
      ? null
      : { certificateContentError: true };
  }
  return null;
};

/**
 * Well-formed message template
 */
const validateStringTemplate = (
  tags: Array<LabelValue<string>> | undefined,
  text: string,
  openTag: string,
  endTag: string
) => {
  if (!text.includes(openTag) && !text.includes(endTag)) {
    return null;
  }
  const tagBeginCount = (text.match(new RegExp(openTag, 'gi')) ?? []).length;
  const tagEndCount = (text.match(new RegExp(endTag, 'gi')) ?? []).length;
  if (tagBeginCount !== tagEndCount) {
    return {
      messageTemplateError: {
        given: text,
        unknownTag: '',
        emptyTag: false,
        noString: false,
        tagBeginCount,
        tagEndCount,
      },
    };
  }
  const re = new RegExp(openTag + '[^(' + openTag + ')(' + endTag + ')]*' + endTag, 'g');
  const foundTags = text.match(re) ?? [];
  for (const tag of foundTags) {
    if (tag !== openTag + endTag) {
      if (tags && !tags.some((t) => t.value.trim() === tag)) {
        return {
          messageTemplateError: {
            given: text,
            unknownTag: tag,
            emptyTag: false,
            noString: false,
            tagBeginCount,
            tagEndCount,
          },
        };
      }
    } else {
      return {
        messageTemplateError: {
          given: text,
          unknownTag: tag,
          emptyTag: true,
          noString: false,
          tagBeginCount,
          tagEndCount,
        },
      };
    }
  }
  return null;
};

export const validatorMessageTemplate =
  (tags: Array<LabelValue<string>> | undefined, openTag = '{{', endTag = '}}') =>
  (control: AbstractControl) => {
    const text = control.value;
    if (isNil(text)) {
      return null;
    } else if ('string' === typeof text) {
      const re = validateStringTemplate(tags, text, openTag, endTag);
      return re;
    }
    return {
      messageTemplateError: {
        given: control.value,
        unknownTag: '',
        emptyTag: false,
        noString: true,
        tagBeginCount: 0,
        tagEndCount: 0,
      },
    };
  };

/**
 * Minimum file size, uninplemented.
 */
export const validatorMinFileSize =
  (minSize: number, minSizeUnit = SIZE_UNITS.KB) =>
  (control: AbstractControl) => {
    if (control.value && minSize > 0) {
      const isValid = isFileSizeValid({ minSize, minSizeUnit })(control.value);
      return isValid ? null : { minFileSize: { given: control.value.size, min: minSize * minSizeUnit } };
    }
    return null;
  };

export const validatorMaxFileSize =
  (maxSize: number, maxSizeUnit = SIZE_UNITS.MB) =>
  (control: AbstractControl) => {
    if (control.value && maxSize < Infinity) {
      const isValid = isFileSizeValid({ maxSize, maxSizeUnit })(control.value);
      return isValid ? null : { maxFileSize: { given: control.value.size, max: maxSize * maxSizeUnit } };
    }
    return null;
  };

/**
 * Maximum number of lines.
 */
export const validatorMaxLines = (max: number) => (control: AbstractControl) => {
  if (control.value && 'string' === typeof control.value) {
    const lines = control.value.match(/\n/g) || '';
    if (lines.length >= max) {
      return { maxLinesError: { given: lines.length + 1, max } };
    }
  }
  return null;
};

/**
 * Multiplier of a number
 */
export const validatorModule = (module: number) => (control: AbstractControl) => {
  if (control.value && Number(control.value) % module) {
    return {
      moduleError: {
        given: control.value,
        module,
      },
    };
  }
  return null;
};

export const validatorHexColor = (control: AbstractControl) => {
  if (control.value && !isHexColor(String(control.value))) {
    return {
      hexColorError: {
        given: control.value,
      },
    };
  }
  return null;
};

export const validatorUrl = (control: AbstractControl) => {
  if (control.value && !isUrl(String(control.value))) {
    return {
      urlError: {
        given: control.value,
      },
    };
  }
  return null;
};

export const validatorWebUrl = (control: AbstractControl) => {
  if (control.value && !isWeburl(String(control.value))) {
    return {
      urlError: {
        given: control.value,
      },
    };
  }
  return null;
};

export const validatorEmmaUrl = (control: AbstractControl) => {
  if (control.value && !isEmmaUrl(String(control.value))) {
    return {
      urlError: {
        given: control.value,
      },
    };
  }
  return null;
};

/**
 * Domain name
 */
export const validatorDomain = (control: AbstractControl) => {
  if (control.value) {
    const isTest = isHostname(control.value);
    if (!isTest) {
      return {
        domainError: {
          given: control.value,
        },
      };
    }
  }
  return null;
};

/**
 * Credit card number. Either:
 * NNNNNNNNNNNNNNNN | NNNNNNNNNNNNNNN
 * NNNN NNNN NNNN NNNN | NNNN NNNNNN NNNNN
 */
export const validatorCreditCardNumber = (control: AbstractControl) => {
  if (control.value) {
    const isTest = regexCardNumber.test(control.value);
    if (!isTest) {
      return {
        creditCardNumberError: {
          given: control.value,
        },
      };
    }
  }
  return null;
};

/**
 * Credit card CVC. NNN | NNNN
 */
export const validatorCreditCardCVC = (control: AbstractControl) => {
  if (control.value) {
    const isTest = regexCardCVC.test(control.value);
    if (!isTest) {
      return {
        creditCardCVCError: {
          given: control.value,
        },
      };
    }
  }
  return null;
};

/**
 * Numbers
 */
export const validatorNumbers = (control: AbstractControl) => {
  if (control.value) {
    const isTest = /^\d*$/.test(control.value);
    if (!isTest) {
      return {
        numbersError: {
          given: control.value,
        },
      };
    }
  }
  return null;
};

/**
 * JSON
 */
export const validatorJson = (control: AbstractControl) => {
  if (control.value) {
    if (!isValidJson(control.value)) {
      return {
        jsonError: {
          given: control.value,
        },
      };
    }
  }
  return null;
};

/**
 * JSON with params
 */
export const validatorJsonWithParams = (control: AbstractControl) => {
  if (control.value) {
    if (!isValidJsonWithParams(control.value)) {
      return {
        jsonWithParamsError: {
          given: control.value,
        },
      };
    }
  }
  return null;
};

export const validatorUrlSuffix = (control: AbstractControl) => {
  if (control.value) {
    if (!isUrlSuffix(control.value)) {
      return {
        urlSuffixError: {
          given: control.value,
        },
      };
    }
  }
  return null;
};

export const validatorEmailList = (control: AbstractControl) => {
  const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (control.value) {
    if (Array.isArray(control.value)) {
      for (const email of control.value) {
        if (!EMAIL_REGEX.test(email)) {
          return {
            error: {
              given: control.value,
            },
          };
        }
      }
    } else {
      return {
        error: {
          given: control.value,
        },
      };
    }
  }
  return null;
};

export const validatorUrlParam = (control: AbstractControl) => {
  if (control.value) {
    if (!regexParamUrl.test(control.value)) {
      return {
        urlParamError: {
          given: control.value,
        },
      };
    }
  }
  return null;
};

/**
 * Only letters as "a_b_c"
 */
export const validatorOnlyLetters = (control: AbstractControl) => {
  if (control.value) {
    const regex = /^[a-z_]+$/i;
    if (!regex.test(control.value)) {
      return {
        urlParamError: {
          given: control.value,
        },
      };
    }
  }
  return null;
};

export const validatorCertificateFingerPrint = (control: AbstractControl) => {
  if (control.value) {
    if (!isCertificateFingerPrint(control.value)) {
      return {
        certificateFingerPrintError: {
          given: control.value,
        },
      };
    }
  }
  return null;
};
