import { animate, state, style, transition, trigger } from '@angular/animations';

const fadeProps = {
  opacity: 0,
  height: 0,
  margin: 0,
  padding: 0,
};
const fadeAnim = '0.25s ease-in-out';

export const sectionAnimations = [
  trigger('fadeScaleInOut', [
    state(
      'void',
      style({
        ...fadeProps,
        transform: 'scale(0.8)',
      })
    ),
    transition('void <=> *', animate(fadeAnim)),
  ]),
  trigger('fadeInOut', [state('void', style(fadeProps)), transition('void <=> *', animate(fadeAnim))]),
  trigger('fadeInOutLeave', [state('void', style(fadeProps)), transition('* => void', animate(fadeAnim))]),
  trigger('fadeInOutEnter', [state('void', style(fadeProps)), transition('void => *', animate(fadeAnim))]),
  trigger('slideRightInOut', [
    state(
      'void',
      style({
        transform: 'translate3d(100%, 0, 0)',
      })
    ),
    transition('void <=> *', animate(fadeAnim)),
  ]),
];
