<footer class="m-grid__item m-footer">
    <div class="m-container m-container--fluid m-container--full-height m-page__container">
        <div class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
            <div class="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
                <span class="m-footer__copyright">
                    <i class="la la-code" aria-hidden="true"></i>
                    &nbsp;
                    <ng-container i18n>Amamantado por</ng-container>
                    &nbsp;
                    <a href="https://emma.io" target="_blank" rel="noopener noreferrer" class="m-link">
                        EMMA
                    </a>
                    · {{ currentYear | date: 'yyyy' }} &copy;
                    <span *ngIf="gitInfo">v{{ gitInfo.version }}.{{ gitInfo.hash }}</span>
                </span>
            </div>
            <div class="m-stack__item m-stack__item--right m-stack__item--middle m-stack__item--first">
                <ul class="m-footer__nav m-nav m-nav--inline m--pull-right">
                    <li class="m-nav__item">
                        <a
                            href="https://skudo.emma.io/es/aviso-legal/"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="m-nav__link"
                        >
                            <span class="m-nav__link-text" i18n>Aviso legal</span>
                        </a>
                    </li>
                    <li class="m-nav__item">
                        <a
                            href="https://skudo.emma.io/es/politica-de-privacidad/"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="m-nav__link"
                        >
                            <span class="m-nav__link-text" i18n>Política de privacidad</span>
                        </a>
                    </li>
                    <li class="m-nav__item">
                        <a
                            href="https://skudo.emma.io/es/politica-de-cookies/"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="m-nav__link"
                        >
                            <span class="m-nav__link-text" i18n>Política de cookies</span>
                        </a>
                    </li>
                    <li class="m-nav__item">
                        <a
                            href="https://skudo.emma.io/es/terminos-y-condiciones/"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="m-nav__link"
                        >
                            <span class="m-nav__link-text" i18n>Términos y condiciones</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>
