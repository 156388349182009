import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EMMALoadingModule } from '@emma-components/emma-loading.module';
import { EMMAPaginationModule } from '@emma-components/emma-pagination.module';
import { EMMAFormModule } from '@emma-components/forms/emma-form.module';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';
import { EMMAPipesModule } from '@emma-pipes/emma-pipes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';

import { EMMACopyToClipboardModule } from '../emma-copy-to-clipboard.module';
import { EMMATableColumnSortIconComponent } from './emma-table-column-sort-icon.component';
import { EMMATableRowActionsComponent } from './emma-table-row-actions.component';
import { EMMATableRowCellComponent } from './emma-table-row-cell.component';
import { EMMATableSummaryRowComponent } from './emma-table-summary-row.component';
import { EMMATableComponent } from './emma-table.component';
import {
  EMMATableActionsLeftTemplateDirective,
  EMMATableActionsRightTemplateDirective,
  EMMATableFooterTemplateDirective,
  EMMATableHeaderTemplateDirective,
  EMMATableNoResultsTemplateDirective,
} from './emma-table.directive';

@NgModule({
  imports: [
    CommonModule,
    EMMACopyToClipboardModule,
    EMMADirectivesModule,
    EMMAFormModule,
    EMMALoadingModule,
    EMMAPipesModule,
    EMMAPaginationModule,
    FormsModule,
    QRCodeModule,
    NgbModule,
  ],
  exports: [
    EMMATableComponent,
    EMMATableRowActionsComponent,
    EMMATableActionsRightTemplateDirective,
    EMMATableActionsLeftTemplateDirective,
    EMMATableNoResultsTemplateDirective,
    EMMATableHeaderTemplateDirective,
    EMMATableFooterTemplateDirective,
  ],
  declarations: [
    EMMATableColumnSortIconComponent,
    EMMATableComponent,
    EMMATableRowActionsComponent,
    EMMATableRowCellComponent,
    EMMATableSummaryRowComponent,
    EMMATableActionsRightTemplateDirective,
    EMMATableActionsLeftTemplateDirective,
    EMMATableNoResultsTemplateDirective,
    EMMATableHeaderTemplateDirective,
    EMMATableFooterTemplateDirective,
  ],
})
export class EMMATableModule {}
