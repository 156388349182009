import { HttpErrorResponse } from '@angular/common/http';

function getValidationErrors(errorDetails: Record<string, unknown>[]) {
  const details = errorDetails.map((d: Record<string, unknown>) => {
    if (d && d.property) {
      return `${d.property}: ${d.fail}`;
    }
    return d;
  });
  const message = $localize`Ha ocurrido un error de validación:`;
  return `${message}</br>${details.join(' | ')}`;
}

function getErrorFromHttpErrorResponse(res: HttpErrorResponse) {
  const { error } = res;
  // Errores de validación
  if (res.status === 0) {
    return $localize`No se pudo completar la solicitud con el servidor. Por favor, inténtalo de nuevo más tarde.`;
  } else if (
    res.status >= 500 ||
    error?.error === 'An error has ocurred. Contact with support.' ||
    error?.error?.includes('ECONNREFUSED')
  ) {
    return $localize`Ha ocurrido un error desconocido. Si el error persiste contacta con soporte@emma.io.`;
  } else if (error.details?.length) {
    return getValidationErrors(error.details);
  } else if (error.error) {
    return `${error.error}`;
  }
  return `${res.statusText} (${res.status}) ${
    res.url && res.url.length > 100 ? `${res.url.substr(0, 100)}...` : res.url
  }`;
}

export function getErrorFromResponse(res: any) {
  let errorInstance: HttpErrorResponse | undefined;
  if (res) {
    if (res instanceof HttpErrorResponse) {
      errorInstance = res;
    } else if (res.error instanceof HttpErrorResponse) {
      errorInstance = res.error;
    }
  }
  if (errorInstance) {
    return getErrorFromHttpErrorResponse(errorInstance);
  } else if (res instanceof Error) {
    // Error no controlado
    return `ERROR: ${res.message}`;
  } else if (res && res.error) {
    return res.error.toString();
  } else {
    return '';
  }
}
