import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EMMADropdownModule } from '@emma-components/emma-dropdown.module';
import { EMMAInfoIconModule } from '@emma-components/emma-info-icon.module';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuillModule } from 'ngx-quill';

import { NgxSliderModule } from 'ngx-slider-v2';
import { EMMACopyToClipboardModule } from '../emma-copy-to-clipboard.module';
import { EMMAHelpVideoModule } from '../emma-help-video.module';
import { EMMADatePickerComponent } from './emma-date-picker.component';
import { EMMADateRangeGroupPickerComponent } from './emma-date-range-group-picker.component';
import { EMMAFormBlockComponent } from './emma-form-block.component';
import { EMMAFormElementModule } from './emma-form-element.module';
import { EMMAFormManyModule } from './emma-form-many/emma-form-many.module';
import { EmmaInputAutocompleteModule } from './emma-input-autocomplete.module';
import { EMMAInputColorComponent } from './emma-input-color.component';
import { EMMAInputFileContentComponent } from './emma-input-file-content.component';
import { EMMAInputFileComponent } from './emma-input-file.component';
import { EMMAInputListComponent } from './emma-input-list.component';
import { EMMAInputComponent } from './emma-input.component';
import { EMMAInputModule } from './emma-input.module';
import { EMMAJsonEditorComponent } from './emma-json-editor.component';
import { EMMALabelComponent } from './emma-label.component';
import { EMMAMultiInputModule } from './emma-multi-input.module';
import { EMMAMultiSliderComponent } from './emma-multi-slider.component';
import { EMMAMultiSwitchListComponent } from './emma-multi-switch-list.component';
import { EMMAMultiTypeInputComponent } from './emma-multitypeinput.component';
import { EMMARichEditorModule } from './emma-rich-editor/emma-rich-editor.module';
import { EMMASelectModule } from './emma-select.module';
import { EMMASliderComponent } from './emma-slider.component';
import { EMMASwitchOrSliderComponent } from './emma-switch-or-slider.component';
import { EMMASwitchModule } from './emma-switch.module';
import { EMMATextAreaModule } from './emma-textarea.module';
import { EMMATimeGroupPickerComponent } from './emma-time-group-picker.component';
import { EMMATimePickerComponent } from './emma-time-picker.component';
import { EMMATimeAgoComponent } from './emma-timeago.component';
import { EMMATimeDurationComponent } from './emma-timeduration.component';
import { EMMAUnlimitedOrNumberInputComponent } from './emma-unlimited-or-number-input.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    EMMACopyToClipboardModule,
    EMMADirectivesModule,
    EMMADropdownModule,
    EMMAFormElementModule,
    EMMAFormManyModule,
    EMMAInfoIconModule,
    EMMAInputModule,
    EmmaInputAutocompleteModule,
    EMMAMultiInputModule,
    EMMARichEditorModule,
    EMMASelectModule,
    EMMASwitchModule,
    FormsModule,
    NgxSliderModule,
    QuillModule,
    EMMAHelpVideoModule,
    EMMATextAreaModule,
  ],
  declarations: [
    EMMADatePickerComponent,
    EMMADateRangeGroupPickerComponent,
    EMMAFormBlockComponent,
    EMMAInputColorComponent,
    EMMAInputFileComponent,
    EMMAInputFileContentComponent,
    EMMAInputListComponent,
    EMMAJsonEditorComponent,
    EMMALabelComponent,
    EMMAMultiSliderComponent,
    EMMAMultiSwitchListComponent,
    EMMAMultiTypeInputComponent,
    EMMASliderComponent,
    EMMASwitchOrSliderComponent,
    EMMATimeAgoComponent,
    EMMATimeDurationComponent,
    EMMATimeGroupPickerComponent,
    EMMATimePickerComponent,
    EMMAUnlimitedOrNumberInputComponent,
  ],
  exports: [
    EMMADatePickerComponent,
    EMMADateRangeGroupPickerComponent,
    EMMAFormBlockComponent,
    EMMAFormManyModule,
    EMMAInputColorComponent,
    EMMAInputFileComponent,
    EMMAInputFileContentComponent,
    EMMAInputListComponent,
    EMMAInputModule,
    EmmaInputAutocompleteModule,
    EMMAJsonEditorComponent,
    EMMALabelComponent,
    EMMAMultiInputModule,
    EMMAMultiSliderComponent,
    EMMAMultiSwitchListComponent,
    EMMAMultiTypeInputComponent,
    EMMARichEditorModule,
    EMMASelectModule,
    EMMASliderComponent,
    EMMASwitchModule,
    EMMASwitchOrSliderComponent,
    EMMATimeAgoComponent,
    EMMATimeDurationComponent,
    EMMATimeGroupPickerComponent,
    EMMATimePickerComponent,
    EMMAUnlimitedOrNumberInputComponent,
    EMMATextAreaModule,
  ],
  providers: [EMMAInputComponent],
})
export class EMMAFormModule {}
