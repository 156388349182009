<a
    *ngIf="helpText"
    class="btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill m-btn--help-label"
    role="button"
    tabindex="-1"
    [ngbPopover]="helpText ? popContent : 'null'"
    triggers="focus"
>
    <i class="la la-{{ icon }}" aria-hidden="true"></i>
</a>
<ng-template #popContent>
    <div [innerHTML]="helpText"></div>
    <ng-container *ngIf="helpLink"><br/><i class='m--font-brand la la-external-link' aria-hidden='true'></i>
        <a class='font-weight-bold m-link' href='{{ helpLink }}' target='_blank' rel='noopener noreferrer'>{{ helpLinkLabel }}</a>
    </ng-container>
</ng-template>
