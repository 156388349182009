<skudo-auth-wrapper [type]="type">
    <div id="allContainer">
        <div class="card-auth m-form">
            <h1 class="title"><span i18n>Esto es </span><span class="skudo">Skudo</span></h1>
            <p class="descript">
                <span i18n
                    ><strong>Hace frío ahí fuera.</strong> Regístrate en Skudo y resguárdate del fraude.</span
                >
            </p>
            <div class="form-group m-form__group m-form__group--md">
                <form #form="ngForm" (submit)="onSubmit($event)">
                    <div emma-label label="Email" class="m--margin-top-10">
                        <emma-input
                            type="email"
                            [(ngModel)]="email"
                            ($change)="verifyEmail()"
                            placeholder="email@dominio.com"
                            [required]="true"
                            name="email"
                        ></emma-input>
                    </div>
                    <div emma-label label="Contraseña" class="m--margin-top-10">
                        <emma-input
                            type="password"
                            [(ngModel)]="password"
                            ($change)="showWarning()"
                            [required]="true"
                            name="password"
                        ></emma-input>
                    </div>
                    <div emma-label label="Confirmar contraseña" class="m--margin-top-10">
                        <emma-input
                            type="password"
                            [(ngModel)]="repeatPassword"
                            ($change)="showWarning()"
                            [required]="true"
                            name="repeatPassword"
                        ></emma-input>
                    </div>
                    <div class="m--margin-top-10 m--margin-bottom-10">
                        <emma-switch id="atc" name="agree" [(ngModel)]="agree" [required]="true">
                            <ng-template emma-switch-label-tmp>
                                <span class="text-start">
                                    <span i18n>He leído y acepto los </span>
                                    <a
                                        href="https://emma.io/terminos-de-uso/"
                                        class="m-link m-link--brand"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        i18n
                                    >
                                        Términos de uso
                                    </a>
                                    <span i18n> y la </span><br />
                                    <a
                                        href="https://emma.io/es/politica-de-privacidad/"
                                        class="m-link m-link--brand"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        i18n
                                    >
                                        Política de privacidad
                                    </a>
                                    <span i18n> de EMMA.</span>
                                </span>
                            </ng-template>
                        </emma-switch>
                    </div>
                    <button
                        type="submit"
                        emmaButton
                        class="registerBtn"
                        color="skudodarkbluewhite"
                        [hasIcon]="true"
                        [isBlock]="true"
                        [loading]="loading.size"
                        [disabled]="!isValid()"
                    >
                        <span>
                            <i class="las la-sign-in-alt fs-4" aria-hidden="true"></i>
                            <span class="m--font-bolder" style="font-size: 1.15rem" i18n>Crear cuenta</span>
                        </span>
                    </button>
                </form>
            </div>
            <div class="form-group m-form__group m-form__group--md">
                <div class="emma-form-social-login">
                    <span i18n>o</span>
                </div>
            </div>
            <div class="form-group m-form__group m-form__group--md">
                <div class="d-flex justify-content-center">
                    <div
                        id="g_id_onload"
                        data-client_id="73280845505-04t8dnopknarhiekp75hp02oamhai2l5.apps.googleusercontent.com"
                        data-context="signup"
                        data-ux_mode="redirect"
                        [attr.data-login_uri]="googleCallbackUrl"
                        data-auto_prompt="false"
                    ></div>

                    <div
                        class="g_id_signin"
                        data-type="standard"
                        data-shape="rectangular"
                        data-theme="outline"
                        data-text="signup_with"
                        [attr.data-locale]="googleButtonLocale"
                        data-size="large"
                        data-logo_alignment="center"
                        data-width="400"
                    ></div>
                </div>
            </div>
            <div class="emma-form-footer">
                <div class="m-login__desc">
                    <span i18n>¿Quieres iniciar sesión?</span>
                    <a
                        [routerLink]="ROUTE.LOGIN"
                        id="m_login_signup"
                        class="m-link m-link--brand m-login__account-link"
                        i18n
                    >
                        Identifícate
                    </a>
                </div>
            </div>
        </div>
        <footer><span i18n>Skudo, parte de </span><strong>ARKANA</strong></footer>
    </div>
</skudo-auth-wrapper>
