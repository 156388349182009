import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { generateClassesArray } from '@emma-helpers/emma-utils';
import { NgbDate, NgbDatepicker, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { DATE_FORMATS } from 'emma-common-ts';
import { get, noop, set } from 'lodash';

import {
  CustomRelativeDateOption,
  DATE_PICK_OUTPUT_MODE,
  dateToNgbDate,
  ngbDateToDate,
  RELATIVE_DATE,
  RELATIVE_RANGE,
} from './emma-date-picker.helpers';
import { getPredefinedDates, getPredefinedRanges } from './emma-date-picker.i18n';
import { EMMAFormElementComponent } from './emma-form-element.component';

export interface EMMADatePickerModel {
  [k: string]: string;
}

const EMMA_DATEPICKER_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => EMMADatePickerComponent),
  multi: true,
};
const EMMA_DATEPICKER_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => EMMADatePickerComponent),
  multi: true,
};

@Component({
  selector: 'emma-date-picker',
  templateUrl: './emma-date-picker.component.html',
  providers: [
    EMMA_DATEPICKER_CONTROL_VALUE_ACCESSOR,
    EMMA_DATEPICKER_VALIDATOR,
    {
      provide: EMMAFormElementComponent,
      useExisting: EMMADatePickerComponent,
    },
  ],
})
export class EMMADatePickerComponent
  extends EMMAFormElementComponent
  implements OnChanges, ControlValueAccessor, Validator
{
  @ViewChild(NgbDropdown, { static: false }) drop!: NgbDropdown;
  @ViewChild(NgbDatepicker, { static: false }) datepicker!: NgbDatepicker;

  // IN
  _fromDate: NgbDate | null = null;
  @Input() fromDate: string | number = '';

  _toDate: NgbDate | null = null;
  @Input() toDate: string | number = '';

  // OUT
  @Output() override $change = new EventEmitter<EMMADatePickerModel>();
  @Output() override $blur = new EventEmitter<EMMADatePickerModel>();
  @Output() $changeFrom = new EventEmitter<string | number>();
  @Output() $changeTo = new EventEmitter<string | number>();

  // options
  /** Show manual input, forgiven with range */
  @Input() allowManualInput = false;
  /** Icon */
  @Input() icon = 'calendar';
  @Input() prefix = '';
  _prefix = '';
  /** Size */
  @Input() reducedSize = false;
  /** Placehorder label */
  @Input() override placeholder = $localize`Fecha`;
  /** Label date format */
  @Input() dateLabelFormat?: DATE_FORMATS;
  /** I/O date format */
  @Input() dateFormat = DATE_FORMATS.API;
  /** $change event keys */
  @Input() fromDateKey = 'from';
  @Input() toDateKey = 'to';
  /** Custom label */
  @Input() label = '';
  _label = '';
  /** Force show calendar */
  @Input() showCalendar = false;
  /** Show predefined label as prefix */
  @Input() showRelativeLabel = true;
  /** Pick date range */
  @Input() pickRange = false;
  /** Pick date string */
  @Input() pickSingleDate = false;
  /** Select model output */
  @Input() outputMode = DATE_PICK_OUTPUT_MODE.MODEL;

  @Input() relativeDateOptions: Array<RELATIVE_DATE | RELATIVE_RANGE> = [RELATIVE_DATE.TODAY];

  predefinedDates: Array<CustomRelativeDateOption> = [];

  // daterangepicker options
  @Input() placement: PlacementArray = [
    'bottom-left',
    'bottom-right',
    'top-left',
    'top-right',
    'top',
    'bottom',
  ];

  hoveredDate: NgbDate | null = null;

  @Input() buttonClass = 'btn-metal';
  _buttonClass: Array<string> = [];

  // limitar selección de fechas
  @Input() minDate = '';
  _minDate: NgbDate | null = null;
  @Input() maxDate = '';
  _maxDate: NgbDate | null = null;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: EMMADatePickerModel | string) => void = noop;

  generateEvent(): EMMADatePickerModel {
    const out: EMMADatePickerModel = {};
    set(out, this.fromDateKey, this._fromDate ? ngbDateToDate(this.dateFormat)(this._fromDate) : '');
    set(out, this.toDateKey, this._toDate ? ngbDateToDate(this.dateFormat)(this._toDate) : '');
    return out;
  }

  onChange(): void {
    this.updateLabel();
    const event = this.generateEvent();
    if (this.outputMode === DATE_PICK_OUTPUT_MODE.STRING) {
      this.onChangeCallback(get(event, this.fromDateKey));
    } else {
      this.onChangeCallback(event);
    }
    this.$change.emit(event);
    this.$changeFrom.emit(get(event, this.fromDateKey));
    this.$changeTo.emit(get(event, this.toDateKey));
  }
  setCustomDate(date: NgbDate): void {
    if (this.pickRange) {
      if (!this._fromDate && !this._toDate) {
        this._fromDate = date;
      } else if (this._fromDate && !this._toDate && !date.before(this._fromDate)) {
        this._toDate = date;
        this.drop.close();
        this.onChange();
      } else {
        this._toDate = null;
        this._fromDate = date;
      }
    } else {
      this._fromDate = date;
      this._toDate = null;
      this.drop.close();
      this.onChange();
    }
  }

  setRelative(opt: CustomRelativeDateOption) {
    const { from, to } = opt;
    this._fromDate = new NgbDate(from.year, from.month, from.day);
    this._toDate = new NgbDate(to.year, to.month, to.day);
    this.drop.close();
    this.onChange();
    this.showCalendar = false;
  }

  getRelativeLabel() {
    if (this.pickRange) {
      if (this._fromDate && this._toDate) {
        const date = this.predefinedDates.find((dateOption) =>
          Boolean(
            this._fromDate &&
              this._toDate &&
              dateOption.from.equals(this._fromDate) &&
              dateOption.to.equals(this._toDate)
          )
        );
        return date ? date.label : '';
      }
    } else if (this._fromDate) {
      const date = this.predefinedDates.find((dateOption) =>
        Boolean(this._fromDate && dateOption.from.equals(this._fromDate))
      );
      return date ? date.label : '';
    }
    return '';
  }

  updateLabel() {
    const formatterDay = ngbDateToDate(this.dateLabelFormat || DATE_FORMATS.DAY);
    const formatterDayMonth = ngbDateToDate(this.dateLabelFormat || DATE_FORMATS.DAY_MONTH);
    const formatterDayMonthYear = ngbDateToDate(this.dateLabelFormat || DATE_FORMATS.DAY_MONTH_YEAR);
    const currentYear = new Date().getFullYear();
    if (this.label) {
      this._label = this.label;
      this._prefix = this.prefix;
      return;
    }

    if (!this._fromDate) {
      this._label = '';
    } else if (this.pickRange) {
      if (!this._toDate) {
        // Nothing
        this._label = '';
      } else if (this._fromDate.equals(this._toDate)) {
        // Same day
        if (currentYear !== this._fromDate.year) {
          // Another year
          this._label = formatterDayMonthYear(this._fromDate);
        } else {
          // This year
          this._label = formatterDayMonth(this._fromDate);
        }
      } else if (this._fromDate.month === this._toDate.month && this._fromDate.year === this._toDate.year) {
        // Same month and year
        if (currentYear !== this._fromDate.year) {
          // Another year
          this._label = `${formatterDay(this._fromDate)} - ${formatterDayMonthYear(this._toDate)}`;
        } else {
          // This year
          this._label = `${formatterDay(this._fromDate)} - ${formatterDayMonth(this._toDate)}`;
        }
      } else if (currentYear !== this._fromDate.year || this._fromDate.year !== this._toDate.year) {
        // Another year or different years
        this._label = `${formatterDayMonthYear(this._fromDate)} - ${formatterDayMonthYear(this._toDate)}`;
      } else {
        // This year and same year
        this._label = `${formatterDayMonth(this._fromDate)} - ${formatterDayMonth(this._toDate)}`;
      }
    } else if (currentYear !== this._fromDate.year) {
      // Another year
      this._label = formatterDayMonthYear(this._fromDate);
    } else {
      // This year
      this._label = formatterDayMonth(this._fromDate);
    }
    if (this.showRelativeLabel) {
      const relativeLabel = this.getRelativeLabel();
      this._prefix = relativeLabel ? `${relativeLabel}: ` : '';
    } else {
      this._prefix = this.prefix;
    }
  }

  updatePredefined() {
    const pDates = getPredefinedDates();
    const pRanges = this.pickRange ? getPredefinedRanges() : {};

    this.predefinedDates = [];
    for (const relativeDate of this.relativeDateOptions) {
      if (relativeDate in pDates) {
        const date = pDates[relativeDate];
        if (
          (!this._minDate || this._minDate.before(date.from) || this._minDate.equals(date.from)) &&
          (!this._maxDate || this._maxDate.after(date.to) || this._maxDate.equals(date.to))
        ) {
          this.predefinedDates.push(pDates[relativeDate]);
        }
      } else if (relativeDate in pRanges) {
        const date = pRanges[relativeDate];
        if (
          (!this._minDate || this._minDate.before(date.from) || this._minDate.equals(date.from)) &&
          (!this._maxDate || this._maxDate.after(date.to) || this._maxDate.equals(date.to))
        ) {
          this.predefinedDates.push(pRanges[relativeDate]);
        }
      }
    }
  }

  override updateClasses(): void {
    this._containerClass = generateClassesArray(
      'emma-date-picker',
      this.allowManualInput ? 'input-group' : '',
      this.containerClass
    );
    this._buttonClass = generateClassesArray(
      'emma-date-picker--button m-btn btn',
      this.reducedSize ? 'btn-sm' : '',
      this.buttonClass
    );
  }

  updateDates() {
    this._fromDate = this.fromDate ? dateToNgbDate(this.dateFormat)(this.fromDate) : null;
    this._toDate = this.toDate ? dateToNgbDate(this.dateFormat)(this.toDate) : null;
  }

  updateLimitDates() {
    this._minDate = this.minDate ? dateToNgbDate(this.dateFormat)(this.minDate) : null;
    this._maxDate = this.maxDate ? dateToNgbDate(this.dateFormat)(this.maxDate) : null;
  }

  onBlur() {
    this.onTouchedCallback();
    this.$blur.emit(this.generateEvent());
  }

  override ngOnChanges(changes: SimpleChanges): void {
    if ('pickSingleDate' in changes) {
      this.outputMode = DATE_PICK_OUTPUT_MODE.STRING;
    }
    if ('fromDate' in changes || 'toDate' in changes || 'dateFormat' in changes || 'pickRange' in changes) {
      this.updateDates();
    }
    if ('minDate' in changes || 'maxDate' in changes) {
      this.updateLimitDates();
    }
    if ('pickRange' in changes || 'minDate' in changes || 'maxDate' in changes) {
      this.updatePredefined();
    }
    this.updateLabel();
    this.updateClasses();
  }

  // From ControlValueAccessor interface
  writeValue(value: EMMADatePickerModel | string | null) {
    if (value === null) {
      this.fromDate = '';
      this.toDate = '';
    } else if ('string' === typeof value) {
      if (this.fromDate !== value || this.toDate !== value) {
        this.fromDate = value;
        this.toDate = value;
      }
    } else {
      const fromDate = get(value, this.fromDateKey);
      if (fromDate !== this.fromDate) {
        this.fromDate = fromDate;
      }
      const toDate = get(value, this.toDateKey);
      if (toDate !== this.toDate) {
        this.toDate = toDate;
      }
    }
    this.updateDates();
    this.updateLabel();
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    // this.onTouchedCallback = fn;
  }

  /** Template helpers */
  isRangeIn(date: NgbDate) {
    if (!this.pickRange || !this._fromDate) {
      return false;
    } else if (this._toDate) {
      return date.after(this._fromDate) && date.before(this._toDate);
    } else if (this.hoveredDate) {
      return date.after(this._fromDate) && date.before(this.hoveredDate);
    }
    return false;
  }

  isRangeFrom(date: NgbDate) {
    if (!this.pickRange || !this._fromDate || (this.hoveredDate && date.after(this.hoveredDate))) {
      return false;
    }
    return date.equals(this._fromDate);
  }

  isRangeTo(date: NgbDate) {
    if (!this.pickRange) {
      return false;
    } else if (this._toDate) {
      return date.equals(this._toDate);
    } else if (this._fromDate && date.after(this._fromDate) && this.hoveredDate) {
      return date.equals(this.hoveredDate);
    }
    return false;
  }

  isSelected(date: NgbDate) {
    return (this._fromDate && date.equals(this._fromDate)) || (this._toDate && date.equals(this._toDate));
  }

  isRelative(dateOption: CustomRelativeDateOption) {
    return (
      this._fromDate &&
      this._toDate &&
      dateOption.from.equals(this._fromDate) &&
      dateOption.to.equals(this._toDate)
    );
  }
}
