function getBrowserLocales(w: Window = window): ReadonlyArray<string> {
  if (!w?.navigator) {
    return [];
  }
  const browserLocales: ReadonlyArray<string> = w.navigator['languages']
    ? w.navigator['languages']
    : [w.navigator.language || w.navigator['browserLanguage'] || w.navigator['userLanguage']];
  return browserLocales.map((locale) => locale.split('_').join('-'));
}

function getBrowserLocale(w: Window = window): string | undefined {
  return getBrowserLocales(w)?.[0];
}

export function getBrowserLanguage(w: Window = window): string | undefined {
  let browserLang = getBrowserLocale(w);
  if (browserLang?.includes('-')) {
    browserLang = browserLang.split('-')[0];
  }
  return browserLang;
}
