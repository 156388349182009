import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { sortByProp } from '@emma-helpers/emma-utils';
import {
  validatorCertificateFingerPrint,
  validatorEmmaUrl,
  validatorModule,
  validatorUrl,
  validatorUrlParam,
  validatorWebUrl,
} from '@emma-helpers/emma-validators.helper';
import { noop } from 'lodash';
import { findBestMatch } from 'string-similarity';

import { VALIDATOR_HELP_ERROR_MSG } from '@emma-i18n/validator-message-error.i18n';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { SelectOption } from '@platform/types';
import { InputType } from 'emma-common-ts/emma';
import { EMMAFormElementComponent } from './emma-form-element.component';

export interface RichSuggestion {
  value: string;
  label: string;
}

const PREV_SUGGESTIONS_SHOWN = 2;

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => EMMAInputComponent),
  multi: true,
};

const VALIDATOR = {
  provide: NG_VALIDATORS,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => EMMAInputComponent),
  multi: true,
};

const getMatches = (input: string, list: string[]) => {
  if (!input || !list.length) {
    return [];
  }
  const lc = input.toLocaleLowerCase();
  const res = list.filter((v) => v.toLocaleLowerCase().includes(lc));
  return res;
};
const getSimilarMatches = (input: string, list: string[]) => {
  if (!input || !list.length || list.includes(input)) {
    return [];
  }
  const { ratings } = findBestMatch(input, list);
  ratings.sort(sortByProp('rating', -1));
  const res = ratings.map((m) => m.target);
  return res;
};
const sortByProximity = (input: string) => {
  const lcin = input.toLocaleLowerCase();
  return (a: string, b: string) => {
    const idxa = a.toLocaleLowerCase().indexOf(lcin);
    const idxb = b.toLocaleLowerCase().indexOf(lcin);
    return idxa - idxb;
  };
};

@Component({
  selector: 'emma-input',
  templateUrl: './emma-input.component.html',
  providers: [
    VALUE_ACCESSOR,
    VALIDATOR,
    {
      provide: EMMAFormElementComponent,
      useExisting: EMMAInputComponent,
    },
  ],
})
export class EMMAInputComponent
  extends EMMAFormElementComponent
  implements OnInit, OnChanges, ControlValueAccessor, Validator
{
  @ViewChild('suggestionList', { static: false }) suggestionList!: ElementRef<HTMLUListElement>;
  @ViewChild('inputElement', { static: true, read: ElementRef }) inputElement!: ElementRef<HTMLInputElement>;
  @ViewChild('popover', { static: false }) popover?: NgbPopover;

  @ViewChild('visButtonn', { static: false }) visButtonn!: ElementRef;

  // + input interface
  @Input()
  override autofocus = false;
  @Input() override placeholder = '';
  @Input() type: InputType = 'text';
  @Input() autocomplete = false;
  @Input() autocapitalize = true;
  @Input() spellcheck = false;
  @Input() inputmode = 'text';
  @Input() visibilityButton = false;
  // Validation options
  @Input() minlength = 0;
  @Input() maxlength = Infinity;
  @Input() min?: number;
  @Input() max?: number;
  @Input() step?: number;
  @Input() isWeburl?: boolean;
  @Input() isCertificateFingerPrint?: boolean;

  // Flags + params
  @Input() showSuggestions = false;
  @Input() maxSuggestions = 0;
  @Input() allowSuggestionsOnly = false;
  @Input() showSuggestionsOnEmpty = false;
  @Input() sortByProximityEnabled = true;
  @Input() similarityEngineEnabled = false;
  @Input() hideOnMatch = true;
  @Input() isOpen = false;
  @Input() selectedByDefault = 0;
  @Input() copyToClipboard = false;
  @Input() mustTrimOnBlur = true;
  @Input() showHelpMessageAtError = false;
  @Input()
  get helpMessageAtError(): string {
    const msg = VALIDATOR_HELP_ERROR_MSG[this.type] ?? '';
    return this._innerHelpMessageAtError ? this._innerHelpMessageAtError : msg;
  }
  set helpMessageAtError(value: string) {
    this._innerHelpMessageAtError = value;
  }
  @Input() helpMessagePosition = 'bottom';

  _innerHelpMessageAtError = '';
  // Values
  // Possible values, filtered internally
  @Input() values: (number | string | RichSuggestion)[] = [];
  _strValues: string[] = [];
  _strValuesLower: string[] = [];
  // Suggestions, filtered externally, shown as they are
  @Input() suggestions: (number | string | RichSuggestion)[] = [];
  _strSuggestions: string[] = [];

  visibleValues: RichSuggestion[] = [];
  @Input() selectedSuggestion = this.selectedByDefault;

  // Style
  @Input() dropdownPosition: 'top' | 'bottom' = 'bottom';
  @Input() inputClass = 'form-control m-input';
  _inputClass: Array<string> = [];

  @Output() override $focus = new EventEmitter<any>();
  @Output() override $blur = new EventEmitter<string | number>();
  @Output() override $change = new EventEmitter<string | number>();
  @Output() override $keydown = new EventEmitter<any>();
  @Output() override $keyup = new EventEmitter<any>();
  @Output() override $keypress = new EventEmitter<any>();
  @Output() $select = new EventEmitter<any>();
  @Output() override $enter = new EventEmitter<any>();

  override isHover = false;
  override isFocus = false;
  isValid = true;

  // Form
  @Input()
  get value(): string | number {
    return this.type === 'number' ? Number(this._innerValue || 0) : String(this._innerValue || '');
  }
  set value(rawValue: string | number) {
    const value = this.type === 'number' ? Number(rawValue || 0) : String(rawValue || '');
    if (value !== this._innerValue) {
      this._innerValue = value;
      this.updateSuggestions();
      this.updateLabel();
      this.onChangeCallback(value);
      this.$change.emit(value);
    }
  }
  private _innerValue: string | number = '';
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: string | number) => void = noop;

  // Select mode
  label = '';
  visibility = false;

  onChangeVisibility(): void {
    this.visibility = !this.visibility;
    this.visButtonn.nativeElement.blur();
  }

  getType(): string {
    if (this.visibilityButton) {
      return this.visibility ? 'text' : 'password';
    }
    return this.type;
  }
  override ngOnInit(): void {
    this.updateClasses();
  }
  override ngOnChanges(changes: SimpleChanges): void {
    if ('autofocus' in changes && this.autofocus) {
      this.inputElement.nativeElement.focus();
      this.inputElement.nativeElement.select();
    }
    if ('values' in changes) {
      this._strValues = this.values?.map((v) => ('object' === typeof v ? v.value : String(v))) || [];
      this._strValuesLower = this._strValues.map((v) => v.toLocaleLowerCase());
      this.updateLabel();
    }
    if ('suggestions' in changes) {
      this._strSuggestions =
        this.suggestions?.map((v) => ('object' === typeof v ? v.value : String(v))) || [];
    }
    if (
      'values' in changes ||
      'suggestions' in changes ||
      'showSuggestions' in changes ||
      'hideOnMatch' in changes ||
      'showSuggestionsOnEmpty' in changes ||
      'sortByProximityEnabled' in changes ||
      'similarityEngineEnabled' in changes
    ) {
      this.updateSuggestions();
    }
    this.updateClasses();
  }
  removeErrorClass() {
    this._inputClass = this._inputClass.filter((cl) => cl !== 'emma-input__primary');
  }
  override updateClasses(): void {
    const containerClasses = ['emma-input', this.containerClass];
    if (this.copyToClipboard) {
      containerClasses.push('emma-input__copyToClipboard');
    }
    if (this.visibilityButton) {
      containerClasses.push('emma-input__visibilityBtn');
    }
    this._containerClass = containerClasses;

    const inputClasses = ['emma-input__input', 'emma-input__primary', this.inputClass];
    if (this.error) {
      inputClasses.push('m-input--ko');
    }
    this._inputClass = inputClasses;
  }

  isSuggestionValue(): boolean {
    return (
      this._strValues.includes(String(this._innerValue)) ||
      this._strSuggestions.includes(String(this._innerValue))
    );
  }

  private getVisibleValues(tmpVisibleValues: string[]): RichSuggestion[] {
    return tmpVisibleValues.map((vv) => {
      const richValue = [...this.values, ...this.suggestions].find(
        (v) => 'object' === typeof v && v.value === vv
      ) as RichSuggestion | undefined;
      return {
        value: vv,
        label: richValue?.label ?? '',
      };
    });
  }

  updateSuggestions(): void {
    let _visibleValues: string[] = [];
    if (this.showSuggestions) {
      if (this.hideOnMatch && this._innerValue && this.isSuggestionValue()) {
        this.visibleValues = [];
      } else if (this._innerValue) {
        const strValue = String(this._innerValue);
        const matches = getMatches(strValue, this._strValues);
        if (this.sortByProximityEnabled) {
          matches.sort(sortByProximity(strValue));
        }
        if (this.similarityEngineEnabled) {
          const similar = getSimilarMatches(strValue, this._strValues);
          _visibleValues = [...this._strSuggestions, ...matches, ...similar];
        } else {
          _visibleValues = [...this._strSuggestions, ...matches];
        }
      } else if (this.showSuggestionsOnEmpty) {
        _visibleValues = [...this._strSuggestions, ...this._strValues];
      }
      this.visibleValues = this.getVisibleValues(_visibleValues);
      if (this.maxSuggestions > 0) {
        this.visibleValues = this.visibleValues.slice(0, this.maxSuggestions);
      }
    } else {
      this.visibleValues = [];
    }
    this.resetSuggestion();
    this.updateOpenState();
  }

  nextSuggestion(): void {
    this.selectedSuggestion = (this.selectedSuggestion + 1) % this.visibleValues.length;
    this.scrollToSuggestion();
  }
  prevSuggestion(): void {
    if (this.selectedSuggestion <= 0) {
      this.selectedSuggestion = this.visibleValues.length - 1;
    } else {
      this.selectedSuggestion = (this.selectedSuggestion - 1) % this.visibleValues.length;
    }
    this.scrollToSuggestion();
  }
  enterSuggestion(): void {
    const selected = this.visibleValues[this.selectedSuggestion];
    if (selected) {
      const suggestion = this.visibleValues[this.selectedSuggestion];
      this.onSelect(suggestion.value);
      this.selectedSuggestion = 0;
    }
    this.updateOpenState();
  }
  resetSuggestion(): void {
    this.selectedSuggestion = this.selectedByDefault;
    this.scrollToSuggestion();
  }
  scrollToSuggestion(): void {
    if (this.suggestionList) {
      const el = this.suggestionList.nativeElement;
      if (el.children.length > 5) {
        const suggestionIndex = this.selectedSuggestion;
        const selected = el.children[suggestionIndex] as HTMLLIElement;
        let heightToScroll = 0;
        for (let i = 0; i < PREV_SUGGESTIONS_SHOWN; ++i) {
          const p = el.children[suggestionIndex - i];
          if (p) {
            heightToScroll += p.clientHeight;
          }
        }
        el.scrollTop = selected.offsetTop - heightToScroll;
      }
    }
  }

  updateOpenState(): void {
    const isOpen =
      this.showSuggestions &&
      !this.disabled &&
      !this.readonly &&
      Boolean(this.visibleValues.length) &&
      (this.isFocus || this.isHover);
    if (this.isOpen !== isOpen) {
      this.isOpen = isOpen;
    }
  }

  updateLabel(): void {
    const option = this.values.find((v) => v === this._innerValue);
    if (option) {
      if ('object' === typeof option) {
        this.label = option.label;
      } else {
        this.label = String(option);
      }
    }
    this.label = String(this._innerValue);
  }

  // Set touched on blur
  onBlur(): void {
    let { value } = this;
    this.onTouchedCallback();
    this.isFocus = false;
    if ('string' === typeof value && this.mustTrimOnBlur) {
      value = value.trim();
    }
    if (this.showSuggestions && this.allowSuggestionsOnly) {
      if (this.visibleValues.length || this.isSuggestionValue()) {
        this.enterSuggestion();
      } else {
        value = '';
      }
    }
    if (this.value !== value) {
      this.value = value;
    }
    this.updateOpenState();
    this.$blur.emit(this.value);
  }
  onFocus(): void {
    this.isFocus = true;
    this.$focus.emit(this.value);
    this.updateOpenState();
  }
  onSuggestionsHover(): void {
    this.isHover = true;
    this.updateOpenState();
  }
  onSuggestionsLeave(): void {
    this.isHover = false;
    this.updateOpenState();
  }

  onSelect(value: string): void {
    this.value = value;
    this.$select.emit(value);
    this.updateOpenState();
  }

  onKeyDown(event: KeyboardEvent): void {
    if (this.hasSuggestions()) {
      if (event.key === 'ArrowDown') {
        this.nextSuggestion();
      } else if (event.key === 'ArrowUp') {
        this.prevSuggestion();
      } else if (event.key === 'Enter' || (this.allowSuggestionsOnly && event.key === 'Tab')) {
        this.enterSuggestion();
        this.$enter.emit(event);
      }
    } else if (event.key === 'Enter') {
      this.$enter.emit(event);
    }
    this.$keydown.emit(event);
  }
  onMouseHover(index: number): void {
    if (this.hasSuggestions()) {
      this.selectedSuggestion = index;
    }
  }
  onChange(event: any): void {
    if (event.target) {
      this.$change.next(event.target.value || '');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  trackByFn(_index: number, item: SelectOption): string {
    return String(item.value);
  }

  hasSuggestions(): boolean {
    return Boolean(this.visibleValues.length);
  }

  focus(options?: FocusOptions): void {
    if (this.inputElement) {
      this.inputElement.nativeElement.focus(options);
    }
  }

  // From ControlValueAccessor interface
  writeValue(rawValue: string | number): void {
    const value = this.type === 'number' ? Number(rawValue || 0) : String(rawValue || '');
    if (value !== this._innerValue) {
      this._innerValue = value;
      this.updateSuggestions();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  checkIfPopoverHelp(): void {
    if (this.showHelpMessageAtError && this.isFocus) {
      this.isValid ? this.popover?.close() : this.popover?.open();
    }
  }

  private addNumericValidators(validators: ValidatorFn[]) {
    if (this.max !== undefined) {
      validators.push(Validators.max(this.max));
    }
    if (this.min !== undefined) {
      validators.push(Validators.min(this.min));
    }
    if (this.step !== undefined) {
      validators.push(validatorModule(this.step));
    }
  }

  // From Validator interface
  override validate: ValidatorFn = (control: AbstractControl) => {
    const validators: ValidatorFn[] = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.maxlength < Infinity) {
      validators.push(Validators.maxLength(this.maxlength));
    }
    if (this.minlength) {
      validators.push(Validators.minLength(this.minlength));
    }
    if (this.isWeburl) {
      validators.push(validatorWebUrl);
    }
    if (this.isCertificateFingerPrint) {
      validators.push(validatorCertificateFingerPrint);
    }
    if (this.validateFn) {
      validators.push(this.validateFn);
    }
    switch (this.type) {
      case 'number':
        this.addNumericValidators(validators);
        break;
      case 'url':
        validators.push(validatorUrl);
        break;
      case 'emmaurl':
        validators.push(validatorEmmaUrl);
        break;
      case 'email':
        validators.push(Validators.email);
        break;
      case 'urlparam':
        validators.push(validatorUrlParam);
        break;
    }
    if (validators.length) {
      const validator = Validators.compose(validators);
      const result = validator ? validator(control) : null;
      this.isValid = !result;
      this.checkIfPopoverHelp();
      return result;
    }
    return null;
  };
}
