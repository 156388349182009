import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EMMADropdownModule } from '@emma-components/emma-dropdown.module';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';

import { EMMAFormElementModule } from '../emma-form-element.module';
import { EMMAFormManyComponent } from './emma-form-many.component';
import {
  EMMAFormManyFooterTplDirective,
  EMMAFormManyHeaderTplDirective,
  EMMAFormManyItemTplDirective,
  EMMAFormManySuffixTplDirective,
} from './emma-form-many.directive';

@NgModule({
  imports: [CommonModule, EMMADirectivesModule, EMMADropdownModule, EMMAFormElementModule, FormsModule],
  declarations: [
    EMMAFormManyComponent,
    EMMAFormManyItemTplDirective,
    EMMAFormManyHeaderTplDirective,
    EMMAFormManyFooterTplDirective,
    EMMAFormManySuffixTplDirective,
  ],
  exports: [
    EMMAFormManyComponent,
    EMMAFormManyItemTplDirective,
    EMMAFormManyHeaderTplDirective,
    EMMAFormManyFooterTplDirective,
    EMMAFormManySuffixTplDirective,
  ],
})
export class EMMAFormManyModule {}
