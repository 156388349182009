import { Component, Input } from '@angular/core';

@Component({
  selector: 'emma-info-icon',
  templateUrl: './emma-info-icon.component.html',
})
export class EMMAInfoIconComponent {
  @Input() helpText = '';
  @Input() helpLink = ''; // https://docs.emma.io
  @Input() helpLinkLabel = $localize`Saber más`;
  @Input() icon = 'question-circle';
}
