import * as gitInfo from '../../../../../git-version.json';

// This includes only used properties
export interface GitInfo {
  dirty: boolean;
  hash: string;
  tag: string | null;
  version: string;
  [key: string]: unknown;
}

export const getGitInfo = (): GitInfo => {
  const defaultGitInfo = {
    dirty: true,
    hash: 'dev',
    tag: null,
    version: '0.0.0',
  };
  return { ...defaultGitInfo, ...gitInfo };
};
