import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';

import { PlatformNotificationFeedbackModule } from '@platform/components/platform-notification-feedback/platform-notification-feedback.module';
import { EMMANotificationAnnouncementComponent } from './emma-notification-announcement.component';
import { EMMANotificationDataMonsterComponent } from './emma-notification-data-monster.component';
import { EMMANotificationTrialAccountComponent } from './emma-notification-trial-account-component';

@NgModule({
  declarations: [
    EMMANotificationAnnouncementComponent,
    EMMANotificationDataMonsterComponent,
    EMMANotificationTrialAccountComponent,
  ],
  imports: [CommonModule, EMMADirectivesModule, PlatformNotificationFeedbackModule],
  exports: [
    EMMANotificationAnnouncementComponent,
    EMMANotificationDataMonsterComponent,
    EMMANotificationTrialAccountComponent,
    PlatformNotificationFeedbackModule,
  ],
})
export class EMMANotificationModule {}
