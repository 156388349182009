import { HttpErrorResponse } from '@angular/common/http';
import { DateRange, regexWeburl } from 'emma-common-ts';
import { EMMA_COSTS, EMMA_EVENTS, EMMA_KPIS, EMMA_LEGACY_KPIS, EMMA_RATIOS } from 'emma-common-ts/emma';

import { regexUrl } from './regex-url.helper';

export function isDateLabel(label: string): boolean {
  return /^[1-2][0-9]{3}-[0-1][0-9]-[0-3][0-9]$/.test(label);
}
export const isEmmaEventToken = (token: string): boolean => Object.values(EMMA_EVENTS).includes(token as any);
export const isCustomEventToken = (token: string): boolean =>
  !Object.values(EMMA_EVENTS).includes(token as any);
export const isEmmaKpi = (field: string): boolean =>
  Object.values(EMMA_KPIS).includes(field as any) || Object.values(EMMA_LEGACY_KPIS).includes(field as any);
export const isEmmaRatio = (ratio: string): boolean => Object.values(EMMA_RATIOS).includes(ratio as any);
export const isEmmaCost = (cost: string): boolean => Object.values(EMMA_COSTS).includes(cost as any);

export const isUrl = (url: string): boolean => {
  try {
    const nurl = new URL(url);
    return Boolean(nurl && regexUrl.test(url));
  } catch (ex) {
    return false;
  }
};
export const isWeburl = (url: string): boolean => {
  try {
    const nurl = new URL(url);
    return Boolean(nurl && regexWeburl.test(url));
  } catch (ex) {
    return false;
  }
};
/**
 * Comprueba que la url no sea HTTP, cualquier otro protocolo se admite puesto que podría ser usado como deeplink para una app, además
 * se comprueba que no se emplee localhost como hostname en la url.
 *
 * @param {string} url - URL sobre la que se hará la comprobación
 * @returns {boolean}
 */
export const isEmmaUrl = (url: string): boolean => {
  try {
    const nurl = new URL(url);
    return Boolean(nurl && nurl.protocol !== 'http:' && nurl.hostname !== 'localhost' && regexUrl.test(url));
  } catch (ex) {
    return false;
  }
};

export const isEqualDateRange = (a: DateRange, b: DateRange): boolean =>
  Boolean(a.startDay === b.startDay && a.endDay === b.endDay && a.timeGroup === b.timeGroup);

export const isHexColor = (color: string): boolean => {
  const hexColorRegExp = new RegExp('^#[a-fA-F0-9]{6}$');
  return Boolean(color) && hexColorRegExp.test(color);
};

export function isErrorStatus(res: any, status: number): boolean {
  let errorInstance: HttpErrorResponse | undefined;
  if (res) {
    if (res instanceof HttpErrorResponse) {
      errorInstance = res;
    } else if (res.error instanceof HttpErrorResponse) {
      errorInstance = res.error;
    }
  }
  if (errorInstance) {
    return errorInstance.status === status;
  }
  return false;
}

/**
 * Identifies if page is a page of admin section.
 * @param url The current url in page.
 * @returns true or false.
 */
export const isAdminPage = (url: string): boolean => url.includes('/admin/');

export const isHostname = (hostname: string): boolean => {
  return /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/.test(hostname);
};

export const isUrlSuffix = (value: string): boolean => {
  return /^[a-zA-Z0-9_~.-]+$/.test(value);
};

export const isCertificateFingerPrint = (value: string): boolean => {
  return /^[A-F0-9]{2}(:[A-F0-9]{2}){16,32}$/.test(value);
};
