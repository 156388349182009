import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnUnsubscribe } from '@platform/helpers/on-unsubscribe.class';
import { ROUTE } from 'emma-common-ts/skudo';

@Component({
  template: '',
})
export class IndexPageComponent extends OnUnsubscribe implements OnInit {
  constructor(private router: Router) {
    super();
  }
  // TODO: Remove this when we're done
  // eslint-disable-next-line class-methods-use-this
  isRouteEnabled(): boolean {
    // TODO: Check permissions
    return true;
  }

  ngOnInit(): void {
    this.router.navigateByUrl(ROUTE.MAIN).catch(console.error);
  }
}
