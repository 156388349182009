import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  Component,
  HostListener,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'skudo-global-loading',
  template: ` <div
    *ngIf="isEnabled"
    [ngClass]="{
      'm-page-loader': true,
      'm-page-loader--non-block': true,
      'm-page--loading-opaque': isInitialLoad
    }"
  >
    <div class="customSKUDOLoading">
      <div class="customSKUDOLoading-Bg">
        <div class="customSKUDOLoading-Iso">
          <img
            id="IsoSKUDO"
            class="svgImage loadingIsoSKUDO"
            src="assets/media/skudo/img/logo/Skudo-Isotipo.svg"
            alt="SKUDO"
            i18n-alt
          />
        </div>
      </div>
    </div>
  </div>`,
})
export class SkudoGlobalLoadingComponent implements OnChanges, AfterContentInit {
  @Input() isInitialLoad = true;
  @Input() isEnabled = true;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('isEnabled' in changes) {
      this.enableDisableLoading();
    }
  }

  enableDisableLoading() {
    if (this.isEnabled) {
      this.document.body.classList.add('m-page--loading-non-block');
    } else {
      this.document.body.classList.remove('m-page--loading-non-block', 'm-page--loading-opaque');
    }
  }

  ngAfterContentInit(): void {
    // Puede ser que los estilos carguen antes que angular.
    if (this.document.readyState === 'complete') {
      if (this.isInitialLoad) {
        this.isInitialLoad = false;
        this.isEnabled = false;
      }
    }
  }

  @HostListener('window:load')
  onLoad(): void {
    // Esperamos a que los estilos esten cargados. Según el navegador este método se ejecuta antes o después.
    if (this.isInitialLoad) {
      this.isInitialLoad = false;
      this.isEnabled = false;
    }
  }
}
