import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { apiFeedback } from '@platform/helpers/api-feedback.helper';
import { getNotificationsPassword } from '@platform/helpers/auth.helper';
import { getApiBaseUrl } from '@platform/helpers/base-url.helper';
import { OnUnsubscribe } from '@platform/helpers/on-unsubscribe.class';
import { API_URL } from '@platform/providers/url.provider';
import { getCurrentLocale } from '@platform/services/locale.service';
import { ToastNotificationsService } from '@platform/services/toast-notifications.service';
import { environment } from '@skudo-environments/environment';
import { UserService } from '@skudo/services/user.service';
import { STATUS } from 'emma-common-ts';
import { MIN_PASSWORD_LENGTH, ROUTE } from 'emma-common-ts/skudo';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AUTH_TYPE } from '../auth-wrapper.component';

@Component({
  selector: 'skudo-register-page',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SkudoRegisterPageComponent extends OnUnsubscribe implements OnInit {
  @ViewChild('form', { static: false }) public form!: NgForm;
  loading = new Set<string>();
  type = AUTH_TYPE.REGISTER;

  password = '';
  repeatPassword = '';
  email = '';
  agree = false;
  notificationsKey = 'passwordFeedback';
  isPasswordValid = false;
  googleCallbackUrl = '';
  googleButtonLocale = '';
  ROUTE = ROUTE;
  siteKey = environment.recaptchaSiteKey;
  captchaResponse = '';

  constructor(
    public notifications: ToastNotificationsService,
    private userService: UserService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    @Inject(API_URL) apiUrl: string
  ) {
    super();
    const googleCallback = getApiBaseUrl(apiUrl);
    this.googleCallbackUrl = googleCallback.endsWith('/')
      ? `${googleCallback}user/sign-up/google/callback`
      : `${googleCallback}/user/sign-up/google/callback`;
    this.googleButtonLocale = getCurrentLocale();
  }

  ngOnInit(): void {
    this.getTokenRecaptcha();
  }

  onSubmit(ev: Event) {
    ev.preventDefault();
    if (this.isValid()) {
      this.onSave();
    }
  }

  getTokenRecaptcha() {
    this.recaptchaV3Service.execute('register').subscribe((token: string) => (this.captchaResponse = token));
  }

  onSave() {
    const singUpData = {
      email: this.email,
      password: this.password,
      agree: this.agree,
      token: this.captchaResponse,
    };

    this.loading.add('signup');
    this.userService
      .signUp(singUpData)
      .pipe(apiFeedback('signup', this.notifications, this.loading))
      .subscribe((data) => {
        this.navigateToLogin(data.status === STATUS.SUCCESS);
      });
  }

  isValid(): boolean {
    return Boolean(this.form?.valid && this.isPasswordValid);
  }

  showWarning() {
    const errors = getNotificationsPassword(MIN_PASSWORD_LENGTH, this.password, this.repeatPassword);
    const activeNotifications = this.notifications.getActives();
    activeNotifications.forEach((notification) => {
      if (!errors.includes(notification.message)) {
        this.notifications.remove(notification.toastId);
      }
    });
    this.isPasswordValid = errors.length === 0;
    if (!this.isPasswordValid) {
      errors.forEach((error) => {
        this.notifications.setError(error, undefined, {
          easeTime: 0,
        });
      });
    }
  }

  verifyEmail() {
    if (this.form.form.controls['email'].status === 'VALID') {
      this.userService.verifyIfUserEmailExists(this.email).subscribe((response) => {
        if (response.status === STATUS.SUCCESS) {
          if (response.data?.isRegistered) {
            this.notifications.setError($localize`Este correo ya ha sido registrado.`);
          }
        }
      });
    }
  }
  private navigateToLogin(signUpSuccess?: boolean) {
    const params = signUpSuccess ? '?signUpSuccess=true' : '';
    this.router.navigateByUrl(`${ROUTE.LOGIN}${params}`).catch((error) => console.error(error));
  }
}
