import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SkudoUiModule } from '../skudo-ui.module';
import { SkudoFooterComponent } from './skudo-footer.component';

@NgModule({
  declarations: [SkudoFooterComponent],
  imports: [CommonModule, SkudoUiModule],
  exports: [SkudoFooterComponent],
})
export class SkudoFooterModule {}
