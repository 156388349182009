import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ScriptInjectorGoogleSignInComponent } from './script-injector-google-signin.component';
import { ScriptInjectorStatuspageComponent } from './script-injector-statuspage.component';
import { SkudoGlobalLoadingComponent } from './skudo-global-loading/skudo-global-loading.component';

@NgModule({
  declarations: [
    ScriptInjectorStatuspageComponent,
    SkudoGlobalLoadingComponent,
    ScriptInjectorGoogleSignInComponent,
  ],
  imports: [CommonModule],
  exports: [
    ScriptInjectorStatuspageComponent,
    SkudoGlobalLoadingComponent,
    ScriptInjectorGoogleSignInComponent,
  ],
})
export class ScriptInjectorsModule {}
