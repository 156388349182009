export interface NotificationParams {
  key: string;
  message: string;
  dismissable: boolean; // toast.closeButton
  announcement?: boolean; // no aplica toast.
  dontShowMore?: boolean;
  timeout?: number;
}

export enum NotificationLevel {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info',
  DEBUG = 'debug',
}

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum NotificationIcon {
  ERROR = 'flaticon-cancel',
  WARNING = 'flaticon-warning-2',
  INFO = 'flaticon-exclamation-2',
  SUCCESS = 'flaticon-exclamation-2',
  DEBUG = 'flaticon-cogwheel-2',
}

export interface BannerNotificationImpl {
  message: string;
  key: string;
  level?: NotificationLevel;
  dismissable?: boolean;
  icon?: NotificationIcon;
  helpLinkText?: string;
  helpLinkUrl?: string;
  contactButton?: boolean;
  dontShowMore?: boolean;
}

export class BannerNotification {
  key: string;
  message: string;
  level?: NotificationLevel;
  dismissable?: boolean;
  icon?: NotificationIcon;
  helpLinkText?: string;
  helpLinkUrl?: string;
  contactButton?: boolean;
  dontShowMore?: boolean;

  constructor(opts: Partial<BannerNotificationImpl> & Pick<BannerNotificationImpl, 'message'>) {
    this.key = opts.key ?? opts.message;
    this.message = opts.message;
    this.level = opts.level ?? NotificationLevel.ERROR;
    this.dismissable = Boolean(opts.dismissable);
    this.icon = opts.icon ?? NotificationIcon.ERROR;
    this.helpLinkText = opts.helpLinkText;
    this.helpLinkUrl = opts.helpLinkUrl;
    this.contactButton = opts.contactButton ?? this.level === NotificationLevel.ERROR;
    this.dontShowMore = opts.dontShowMore;
    this.helpLinkUrl = opts.helpLinkText ?? 'https://docs.emma.io';
  }
}
