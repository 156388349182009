<div [id]="id" [class]="klass">
    <ul class="m-menu__nav m-menu__nav--submenu-arrow">
        <ng-container *ngFor="let item of menuItems">
            <li
                *ngIf="!item.hidden"
                [ngClass]="{
                    'm-menu__item': true,
                    'm-menu__item--rel': true,
                    'm-menu__item--active': isActive(item),
                    'm-menu__item--disabled': item.disabled
                }"
            >
                <span
                    *ngIf="!item.disabled"
                    class="m-menu__link"
                    [routerLink]="item.route"
                    routerLinkActive="m-menu__item--active"
                    [routerLinkActiveOptions]="{ exact: true }"
                >
                    <i *ngIf="item.icon" [class]="'m-menu__link-icon la la-' + item.icon" aria-hidden="true"></i>
                    <span class="m-menu__link-text">{{ item.title }}</span>
                </span>
                <span
                    *ngIf="item.disabled"
                    routerLinkActive="m-menu__item--active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="m-menu__link"
                >
                    <i *ngIf="item.icon" [class]="'m-menu__link-icon la la-' + item.icon" aria-hidden="true"></i>
                    <span class="m-menu__link-text">{{ item.title }}</span>
                </span>
            </li>
        </ng-container>
    </ul>
</div>
