import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { EMMAFormElementModule } from './emma-form-element.module';
import { EMMASelectComponent } from './emma-select.component';

@NgModule({
  imports: [
    CommonModule,
    EMMADirectivesModule,
    EMMAFormElementModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
  ],
  declarations: [EMMASelectComponent],
  exports: [EMMASelectComponent],
})
export class EMMASelectModule {}
