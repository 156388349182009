import { LOCAL_STORAGE_KEY, LocalStorageService } from '@skudo/services/local-storage.service';
import { AccountUser, User } from 'emma-common-ts/skudo';

export const getAuthKeys = (): { accessToken: string; apiKey: string } | null => {
  const localStorageService = new LocalStorageService();
  const currentAccount = localStorageService.get<AccountUser>(LOCAL_STORAGE_KEY.CURRENT_ACCOUNT);
  const currentUser = localStorageService.get<User>(LOCAL_STORAGE_KEY.CURRENT_USER);
  if (currentAccount && currentUser) {
    const accessToken = currentUser.access_token;
    const apiKey = currentAccount.apiKey;
    return {
      accessToken,
      apiKey,
    };
  }
  return null;
};
