import { Injectable } from '@angular/core';
import { isEqualDateRange } from '@emma-helpers/is-function.helper';
import { DateRange, DATE_FORMATS, TIME_GROUP } from 'emma-common-ts';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DateChangeService {
  private $dateRangeChange: BehaviorSubject<DateRange>;

  constructor() {
    const startDay = moment().subtract(6, 'days').format(DATE_FORMATS.API);
    const endDay = moment().format(DATE_FORMATS.API);
    this.$dateRangeChange = new BehaviorSubject<DateRange>({
      startDay,
      endDay,
      timeGroup: TIME_GROUP.DAY,
    });
  }

  getChangeEmitter() {
    return this.$dateRangeChange;
  }

  getCurrentDate() {
    return this.$dateRangeChange.getValue();
  }

  forceUpdate() {
    this.$dateRangeChange.next(this.$dateRangeChange.getValue());
  }

  changeDate(newDateRange: DateRange) {
    if (!isEqualDateRange(newDateRange, this.$dateRangeChange.value)) {
      this.$dateRangeChange.next(newDateRange);
    }
  }
}
