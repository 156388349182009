<div [ngClass]="_containerClass">
    <emma-input
        *ngIf="allowManualInput && !pickRange"
        [disabled]="disabled"
        [readonly]="readonly"
        [required]="!!required"
        [(ngModel)]="fromDate"
        ($blur)="onBlur()"
        class="emma-date-picker--input"
    ></emma-input>
    <div
        [class.emma-date-picker--drop]="true"
        [class.emma-date-picker--drop-range]="pickRange"
        ngbDropdown
        #drop="ngbDropdown"
        [placement]="placement"
        autoClose="outside"
    >
        <button
            (blur)="onBlur()"
            [ngClass]="_buttonClass"
            [disabled]="disabled || readonly"
            ngbDropdownToggle
        >
            <i *ngIf="icon" [class]="'la la-' + icon" aria-hidden="true"></i>
            <span *ngIf="_prefix" class="emma-date-picker--button--prefix">{{ _prefix }}</span>
            <span *ngIf="_label" class="emma-date-picker--button--label">{{ _label }}</span>
            <span *ngIf="!_label" class="emma-date-picker--button--placeholder">{{ placeholder }}</span>
        </button>
        <div ngbDropdownMenu class="emma-date-picker--menu">
            <div class="dropdown-menu--grid-tiles">
                <div class="dropdown-menu--tile emma-date-picker--predefined">
                    <button
                        *ngFor="let dateOption of predefinedDates"
                        [disabled]="disabled || readonly"
                        [ngClass]="{
                            'emma-date-picker--predefined-item': true,
                            active: !showCalendar && isRelative(dateOption)
                        }"
                        ngbDropdownItem
                        (click)="setRelative(dateOption)"
                        (blur)="onBlur()"
                    >
                        {{ dateOption.label }}
                    </button>
                    <button
                        *ngIf="predefinedDates.length"
                        [disabled]="disabled || readonly"
                        [ngClass]="{
                            'emma-date-picker--predefined-item': true,
                            active: showCalendar
                        }"
                        ngbDropdownItem
                        (click)="showCalendar = true"
                        (blur)="onBlur()"
                        [ngSwitch]="pickRange"
                    >
                        <span *ngSwitchCase="true" i18n>Rango personalizado</span>
                        <span *ngSwitchDefault i18n>Fecha personalizada</span>
                    </button>
                </div>
                <div class="dropdown-menu--tile emma-date-picker--calendar">
                    <ngb-datepicker
                        #datepicker="ngbDatepicker"
                        *ngIf="!predefinedDates.length || showCalendar"
                        class="emma-date-picker--calendar-item"
                        (dateSelect)="setCustomDate($event)"
                        [startDate]="_fromDate"
                        [dayTemplate]="dayTemplate"
                        [footerTemplate]="footerTemplate"
                        [displayMonths]="pickRange ? 2 : 1"
                        navigation="arrows"
                        [minDate]="_minDate"
                        [maxDate]="_maxDate"
                    ></ngb-datepicker>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #dayTemplate let-date let-focused="focused" let-today="today">
    <div
        class="emma-date-picker--day"
        [class.today]="today"
        [class.focused]="focused"
        [class.selected]="isSelected(date)"
        [class.rangeFrom]="isRangeFrom(date)"
        [class.rangeTo]="isRangeTo(date)"
        [class.rangeIn]="isRangeIn(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
    >
        {{ date.day }}
    </div>
</ng-template>
<ng-template #footerTemplate></ng-template>
