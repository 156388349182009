import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiAuthInterceptor as PlatformApiAuthInterceptor } from '@platform/interceptors/api-auth.interceptor';
import { AuthenticationService } from '@platform/services/authentication.service';
import { BackToLoginService } from '@skudo/services/back-to-login.service';

@Injectable({ providedIn: 'root' })
export class ApiAuthInterceptor extends PlatformApiAuthInterceptor {
  constructor(backToLoginService: BackToLoginService, authService: AuthenticationService, router: Router) {
    super(backToLoginService, authService, router);
  }
}
