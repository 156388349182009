import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EMMAFormElementModule } from '@emma-components/forms/emma-form-element.module';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';
import { PlatformModule } from '@platform/platform.module';
import { EMMAModalModule } from './emma-modal.module';
import { EMMATextCheckModalComponent } from './emma-text-check-modal.component';

@NgModule({
  imports: [
    CommonModule,
    EMMADirectivesModule,
    EMMAFormElementModule,
    EMMAModalModule,
    FormsModule,
    PlatformModule,
  ],
  declarations: [EMMATextCheckModalComponent],
  exports: [EMMATextCheckModalComponent],
})
export class EMMAModalTextCheckModule {}
