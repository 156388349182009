import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { EMMACopyToClipboardModule } from '../emma-copy-to-clipboard.module';
import { EMMAFormElementModule } from './emma-form-element.module';
import { EMMAInputComponent } from './emma-input.component';

@NgModule({
  imports: [
    CommonModule,
    EMMACopyToClipboardModule,
    EMMADirectivesModule,
    EMMAFormElementModule,
    FormsModule,
    NgbModule,
  ],
  declarations: [EMMAInputComponent],
  exports: [EMMAInputComponent],
})
export class EMMAInputModule {}
