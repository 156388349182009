import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EMMAMissingAlertComponent } from './emma-missing-alert.component';

@NgModule({
  imports: [CommonModule],
  exports: [EMMAMissingAlertComponent],
  declarations: [EMMAMissingAlertComponent],
})
export class EMMAMissingAlertModule {}
