import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule } from '@angular/forms';
import { EMMADropdownModule } from '@emma-components/emma-dropdown.module';
import { EMMAInfoIconModule } from '@emma-components/emma-info-icon.module';
import { EMMAModalModule } from '@emma-components/modals/emma-modal.module';
import { EMMAModalTextCheckModule } from '@emma-components/modals/emma-text-check-modal.module';
import { EMMAFormElementModule } from './emma-form-element.module';
import { EMMATextAreaComponent } from './emma-textarea.component';
import {
  EMMATextAreaFooterDirective,
  EMMATextAreaHeaderDirective,
  EMMATextAreaHighlightDirective,
} from './emma-textarea.directive';

@NgModule({
  imports: [
    CommonModule,
    EMMADirectivesModule,
    EMMAFormElementModule,
    NgbModule,
    EMMAModalModule,
    FormsModule,
    EMMADropdownModule,
    EMMAInfoIconModule,
    EMMAModalTextCheckModule,
  ],
  declarations: [
    EMMATextAreaComponent,
    EMMATextAreaHighlightDirective,
    EMMATextAreaFooterDirective,
    EMMATextAreaHeaderDirective,
  ],
  exports: [EMMATextAreaComponent],
  providers: [],
})
export class EMMATextAreaModule {}
