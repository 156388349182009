import { NgModule } from '@angular/core';

import { CanEditDirective } from './can-edit.directive';
import { CanViewDirective } from './can-view.directive';

@NgModule({
  declarations: [CanViewDirective, CanEditDirective],
  imports: [],
  exports: [CanViewDirective, CanEditDirective],
})
export class CanDirectivesModule {}
