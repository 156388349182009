import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

export enum DATE_PICK_OUTPUT_MODE {
  MODEL = 'model',
  STRING = 'string',
}

export enum RELATIVE_DATE {
  TODAY = 1,
  YESTERDAY,
  TOMORROW,
  IN_A_WEEK,
  IN_15_DAYS,
  IN_30_DAYS,
  END_OF_MONTH,
  START_OF_NEXT_MONTH,
  END_OF_NEXT_MONTH,
}

export enum RELATIVE_RANGE {
  LAST_7_DAYS = 10001,
  NEXT_7_DAYS,
  LAST_30_DAYS,
  NEXT_30_DAYS,
  CURRENT_MONTH,
  LAST_MONTH,
  NEXT_MONTH,
}

export interface CustomRelativeDateOption {
  label: string;
  from: NgbDate;
  to: NgbDate;
}
export interface CustomRelativeDateDefinitions {
  [relativeDate: string]: CustomRelativeDateOption;
}

export const ngbDateToDate = (format: string) => (date: NgbDateStruct) =>
  moment([date.year, date.month - 1, date.day]).format(format);

export const dateToNgbDate = (format: string) => (date: string | number) => {
  return momentToNgbDate(moment(date, format));
};

export const momentToNgbDate = (md: moment.Moment) => new NgbDate(md.year(), md.month() + 1, md.date());
