import { Injectable } from '@angular/core';
import { getErrorText } from '@emma-helpers/emma-utils';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastNotificationsService {
  constructor(private toastService: ToastrService) {}

  getActives(): ActiveToast<unknown>[] {
    return this.toastService.toasts;
  }

  clear() {
    this.toastService.clear();
  }

  remove(toastId: number) {
    this.toastService.remove(toastId);
  }

  setError(message: string, title?: string, options?: Partial<IndividualConfig>): ActiveToast<unknown> {
    return this.toastService.error(getErrorText(message), title, {
      disableTimeOut: true,
      ...options,
    });
  }

  setWarning(message: string, title?: string, options?: Partial<IndividualConfig>): ActiveToast<unknown> {
    return this.toastService.warning(message, title, { timeOut: 10000, ...options });
  }

  setSuccess(message: string, title?: string, options?: Partial<IndividualConfig>): ActiveToast<unknown> {
    return this.toastService.success(message, title, options);
  }

  setInfo(message: string, title?: string, options?: Partial<IndividualConfig>): ActiveToast<unknown> {
    return this.toastService.info(message, title, options);
  }
}
