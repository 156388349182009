<style>
    #allContainer {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 100vh;
        background-image: url('assets/media/skudo/img/base/skudo_bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom right;
        position: relative;
        font-family: 'Work Sans', sans-serif;
    }

    .card {
        flex: 0 1 auto;
        margin-top: auto;
        margin-bottom: 150px;
        margin-left: 150px;
        background-color: #fff;
        border-radius: 4px;
        padding: 45px 40px;
        color: #14213d;
        font-weight: 700;
        max-width: 480px;
    }

    .card p.title {
        font-size: 3rem;
        margin: 0;
    }

    .card p.descript {
        font-size: 1.35rem;
        margin: 0;
        font-weight: 400;
    }

    .card p span.skudo {
        color: #f3a229;
    }

    .card p.lead {
        font-weight: 400;
        margin-top: 30px;
    }

    .card p.lead a {
      font-weight: 700;
    }

    footer {
        font-size: 1.2rem;
        align-self: center;
        padding: 10px;
    }

    @media (max-width: 768px) {
        .card {
            margin-bottom: 40px;
            margin-left: 40px;
            margin-right: 40px;
            max-width: none;
        }
    }
</style>

<div id="allContainer">
    <div class="card">
        <p class="title"><span i18n>Esto es </span><span class="skudo">Skudo</span></p>
        <p class="descript">
            <strong i18n>Hace frío ahí fuera. </strong><span i18n>Pronto podrás resguardarte en Skudo.</span>
        </p>
        <p *ngIf="user" class="lead">
            <span i18n>Bienvenido </span><strong>{{ user.email }}</strong>
            <button emmaButton color="skudodarkbluewhite" (click)="logout()">Logout</button>
        </p>
    </div>
    <footer>
        <span i18n>Skudo, parte de </span><strong>ARKANA</strong>
    </footer>
</div>
