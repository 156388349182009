import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EMMAPaginationComponent } from './emma-pagination.component';

@NgModule({
  imports: [CommonModule],
  exports: [EMMAPaginationComponent],
  declarations: [EMMAPaginationComponent],
})
export class EMMAPaginationModule {}
