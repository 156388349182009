import { NgModule } from '@angular/core';

import { CanDirectivesModule } from './can-directives.module';
import { EMMAButtonDirective } from './emma-button.directive';

@NgModule({
  declarations: [EMMAButtonDirective],
  imports: [CanDirectivesModule],
  exports: [EMMAButtonDirective, CanDirectivesModule],
})
export class EMMADirectivesModule {}
