import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BackToLoginService as PlatformBackToLoginService } from '@platform/services/back-to-login.service';

@Injectable({ providedIn: 'root' })
export class BackToLoginService extends PlatformBackToLoginService {
  constructor(router: Router) {
    super(router);
  }
}
