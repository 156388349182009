export const getDashboardHostname = (_w: Window = window): string => _w.location.hostname;
export const getApiHostname = (_w: Window = window): string => {
  if (_w.location.hostname.startsWith('ng.')) {
    const endHostname = _w.location.hostname.split('.').slice(1);
    return `ws.${endHostname.join('.')}`;
  } else {
    return `ws.${_w.location.hostname}`;
  }
};
export const getDashboardBaseUrl = (_url: string, _w: Window = window): string =>
  _url || `${_w.location.protocol}//${getDashboardHostname(_w)}`;
export const getApiBaseUrl = (_url: string | undefined, _w: Window = window): string =>
  _url || `${_w.location.protocol}//${getApiHostname(_w)}`;
