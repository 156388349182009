import { Component, Input, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import copyToClipboard from 'clipboard-copy';

@Component({
  selector: '[emmaCopyToClipboard]',
  templateUrl: './emma-copy-to-clipboard.component.html',
})
export class EMMACopyToClipboardComponent {
  @ViewChild('popover', { static: false }) popover?: NgbPopover;
  @Input() copyValue: string | number = '';

  @Input() copyButtonClass = 'm-btn--copy-url';

  private popoverTimer: NodeJS.Timeout | undefined;
  private showPopover = () => {
    if (this.popoverTimer) {
      clearTimeout(this.popoverTimer);
    }
    this.popover?.open();
    this.popoverTimer = setTimeout(() => this.popover?.close(), 1000);
  };

  copyToClipboard = (): void => {
    if (this.copyValue) {
      copyToClipboard(String(this.copyValue)).catch(console.error);
    }
    this.showPopover();
  };
}
