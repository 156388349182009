import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isMenuActiveRoute } from '@platform/helpers/menu-item.helper';
import { ROUTE } from 'emma-common-ts/skudo';
export interface MenuItem {
  title: string;
  icon: string;
  route?: string;
  disabled?: boolean;
  hidden?: boolean;
}

@Component({
  selector: 'skudo-header-menu',
  templateUrl: './skudo-header-menu.component.html',
  styleUrls: ['./skudo-header-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkudoHeaderMenuComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('class') klass = '';
  @Input() id = '';
  ROUTE = ROUTE;
  isActive = isMenuActiveRoute;
  menuItems: Array<MenuItem> = [
    {
      title: $localize`General`,
      icon: '',
      route: ROUTE.MAIN,
    },
    {
      title: $localize`Campañas`,
      icon: '',
      route: ROUTE.CAMPAIGNS,
    },
    {
      title: $localize`Ofertas`,
      icon: '',
      route: ROUTE.OFFERS,
    },
    {
      title: $localize`Proveedores`,
      icon: '',
      route: ROUTE.PROVIDERS,
    },
    {
      title: $localize`Informes`,
      icon: '',
      route: ROUTE.REPORTS,
    },
    {
      title: $localize`Eventos`,
      icon: '',
      route: ROUTE.EVENTS,
    },
    {
      title: $localize`Fraude`,
      icon: '',
      route: ROUTE.FRAUD,
    },
  ];
}
