import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiSwitchModule } from 'ngx-ui-switch';

import { EMMASwitchComponent } from './emma-switch.component';
import {
  EMMASwitchLabelTemplateDirective,
  EMMASwitchSiblingTemplateDirective,
} from './emma-switch.directive';

@NgModule({
  declarations: [EMMASwitchComponent, EMMASwitchLabelTemplateDirective, EMMASwitchSiblingTemplateDirective],
  imports: [CommonModule, UiSwitchModule],
  exports: [EMMASwitchComponent, EMMASwitchLabelTemplateDirective, EMMASwitchSiblingTemplateDirective],
  providers: [],
})
export class EMMASwitchModule {}
