import {
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { sectionAnimations } from '@platform/helpers/animations.helper';
import { noop } from 'lodash';

import { EMMAFormElementComponent } from './emma-form-element.component';
import {
  EMMASwitchLabelTemplateDirective,
  EMMASwitchSiblingTemplateDirective,
} from './emma-switch.directive';

const EMMA_SWITCH_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => EMMASwitchComponent),
  multi: true,
};

const EMMA_SWITCH_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => EMMASwitchComponent),
  multi: true,
};

@Component({
  selector: 'emma-switch',
  templateUrl: './emma-switch.component.html',
  animations: [...sectionAnimations],
  providers: [
    EMMA_SWITCH_CONTROL_VALUE_ACCESSOR,
    EMMA_SWITCH_VALIDATOR,
    {
      provide: EMMAFormElementComponent,
      useExisting: EMMASwitchComponent,
    },
  ],
})
export class EMMASwitchComponent
  extends EMMAFormElementComponent
  implements OnInit, OnDestroy, OnChanges, ControlValueAccessor, Validator
{
  public innerValue = false;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: boolean) => void = noop;

  @ContentChild(EMMASwitchSiblingTemplateDirective, { read: TemplateRef, static: false })
  siblingTemplate!: TemplateRef<any>;
  @ContentChild(EMMASwitchLabelTemplateDirective, { read: TemplateRef, static: false })
  labelTemplate!: TemplateRef<any>;

  @Input() checkedColor:
    | 'brand'
    | 'danger'
    | 'warning'
    | 'info'
    | 'altgreen'
    | 'altpink'
    | 'altblue'
    | 'altorange'
    | 'altyellow'
    | 'altviolet' = 'brand';
  @Input() uncheckedColor:
    | 'brand'
    | 'danger'
    | 'warning'
    | 'info'
    | 'altgreen'
    | 'altpink'
    | 'altblue'
    | 'altorange'
    | 'altyellow'
    | 'altviolet' = 'brand';
  @Input() size: 'small' | 'large' | 'medium' = 'medium';
  @Input() label = '';
  @Input() reverse!: boolean;
  @Input() checkedLabel?: string;
  @Input() uncheckedLabel?: string;
  @Input() descriptionOn?: string;
  @Input() descriptionOff?: string;
  @Output() checkedChange = new EventEmitter<boolean>();

  // get accessor
  @Input()
  get checked(): boolean {
    return this.reverse ? !this.innerValue : this.innerValue;
  }

  // set accessor including call the onchange callback
  set checked(rawValue: boolean) {
    this.writeValue(rawValue);
    this.onChangeCallback(this.checked);
  }

  onChange(checked: boolean): void {
    this.innerValue = checked;
    this.checkedChange.emit(this.checked);
    this.onChangeCallback(this.checked);
  }

  // Set touched on blur
  onBlur(): void {
    this.onTouchedCallback();
    this.$blur.emit(this.checked);
  }

  // From ControlValueAccessor interface
  writeValue(rawValue: boolean | number): void {
    const isChecked = this.reverse ? !rawValue : !!rawValue;
    if (isChecked !== this.innerValue) {
      this.innerValue = isChecked;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  // From Validator interface
  override validate: ValidatorFn = (control: AbstractControl) => {
    const validators: ValidatorFn[] = [];
    if (this.required) {
      validators.push(Validators.requiredTrue);
    }
    if (this.validateFn) {
      validators.push(this.validateFn);
    }
    if (validators.length) {
      const validator = Validators.compose(validators);
      return validator ? validator(control) : null;
    }
    return null;
  };
}
